<template>
  <component
    :is="getComponentFor(svgName)"
    v-if="validName"
  />
</template>

<script
  setup
  lang="ts"
>
import { computed, defineAsyncComponent, inject } from 'vue';
// import { useRoute, useRouter } from 'vue-router';
// import type { AxiosStatic } from 'axios';

const props = defineProps({
  svgName: {
    type: String,
    required: true,
  },
});

// const $router = useRouter();
// const $route = useRoute();
// const $http: undefined | AxiosStatic = inject('$http');
const $log: any = inject('$log');

const validName = computed(() => {
  const slashCount = (props.svgName.match(/\//g) || []).length;
  const expectedSlashCount = 1;

  if (slashCount !== expectedSlashCount) {
    const errorMessage = `svgName must contain exactly one '/' and reside within '/src/assets/svg'. Invalid svgName: ${props.svgName}`;
    $log.error(errorMessage);
    return false;
  }

  return true;
});

function getComponentFor(svgName: string) {
  if (!validName.value) {
    return null;
  }
  const nameParts = svgName.split('/');
  if (nameParts.length > 1) {
    return defineAsyncComponent(() => import((`../../assets/svg/${nameParts[0]}/${nameParts[1]}.svg`)));
  }
  return defineAsyncComponent(() => import((`../../assets/svg/${nameParts[0]}.svg`)));
}
</script>
