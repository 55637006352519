// Pinia Store
import { computed, inject, ref } from 'vue';
import type { AxiosStatic } from 'axios';
import type { Router } from 'vue-router';
// import type { App } from 'vue';
// import type { Router } from 'vue-router';
import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

import PushNotification from '@/push-notification';

import { useAccountStore } from '@/stores/account';
import { useSocketStore } from '@/stores/socket';
import { useGlobalStore } from '@/stores/global';
// import { useTranslationStore } from '@/stores/translation';
import { useTreasureQuestStore } from '@/stores/treasureQuest';

console.log('Pinia Auth store is being created.'); // no access to Vue.prototype.$log here yet

// Feature detect + local reference
let storage: Storage | null;
let sessionStorage: Storage | null;
let fail;
let failSessionStorage;
let uid;
try {
  uid = new Date().toString();
  (storage = window.localStorage).setItem(uid, uid);
  (sessionStorage = window.sessionStorage).setItem(uid, uid);
  fail = storage.getItem(uid) !== uid.toString();
  failSessionStorage = sessionStorage.getItem(uid) !== uid.toString();
  storage.removeItem(uid);
  sessionStorage.removeItem(uid);
  if (fail) {
    storage = null;
  }
  if (failSessionStorage) {
    sessionStorage = null;
  }
} catch (exception) {
  const exceptionName = exception && typeof exception === 'object' && 'name' in exception && exception.name;
  console.log(`Could not access the local storage: ${exceptionName}`);
}
//

let router: Router;

const STATUS = {
  SIGNING_IN: 1,
  SIGNED_IN: 2,
  ERROR: 3,
  SIGNING_OUT: 4,
  SIGNED_OUT: 5,
};

interface Tokens {
  accessToken: undefined | string;
  refreshToken: undefined | string;
}

// function injectApp(appParam: App) {
//   app = appParam;
//   $log.debug('auth: injectApp OK');
//   PushNotification.injectApp(app);
// }

function injectRouter(routerParam: Router) {
  router = routerParam;
}

const useAuthStore = defineStore('auth', () => {
  // const router = useRouter(); // not working, will be injected via action
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const storageRefreshToken = useStorage('refresh-token', '');
  const storageOriginalRefreshToken = useStorage('original-token', '');
  const storageOriginalWindowHref = useStorage('original-window-href', '');

  function getLocalTokens() {
    console.log('stores/auth: getLocalTokens');
    // TODO: move from local storage to cookies
    if (!storage) {
      return {};
    }
    return {
      accessToken: storage.getItem('access-token') || '',
      refreshToken: storageRefreshToken.value,
    };
  }

  const initialized = ref(false);
  const status = ref(STATUS.SIGNED_OUT);
  const accessToken = ref<undefined | string>(getLocalTokens().accessToken);
  const refreshToken = ref<undefined | string>(getLocalTokens().refreshToken);
  const refreshTokenPromise = ref<undefined | Promise<Tokens>>(undefined);

  const isAuthenticated = computed((): boolean => initialized.value && !!accessToken.value && !!refreshToken.value); // 2023-08-23 verify both tokens, as we can have the accessToken set during the GetReady program before registration, and we don't want this to return true in that case
  const isSigningOut = computed((): boolean => status.value === STATUS.SIGNING_OUT);
  const isImpersonating = computed(() => Boolean(storageOriginalRefreshToken.value));

  function updateRequestHeaders(accessTokenArg?: string) {
    if (!$http) {
      $log.warn('updateRequestHeaders: no $http');
      return;
    }
    if (accessTokenArg) {
      $log.debug('updateRequestHeaders: SET $http.defaults.headers.common.Authorization with new access token.');
      $http.defaults.headers.Authorization = `Bearer ${accessTokenArg}`;
    } else {
      $log.debug('updateRequestHeaders: DELETED $http.defaults.headers.common.Authorization (no access token)');
      delete $http.defaults.headers.Authorization;
    }
  }

  function setLocalTokensAndUpdateRequestHeader({ accessToken: accessTokenArg, refreshToken: refreshTokenArg }: Tokens) {
    if (accessTokenArg) {
      updateRequestHeaders(accessTokenArg);
      if (storage) {
        storage.setItem('access-token', accessTokenArg);
      }
    }
    if (refreshTokenArg) {
      if (storage) {
        storageRefreshToken.value = refreshTokenArg;
      }
    }
  }

  function clearLocalTokensAndUpdateRequestHeader() {
    if (storage) {
      storage.removeItem('access-token');
      storageRefreshToken.value = null;
    }
    updateRequestHeaders();
  }

  function resetState() {
    initialized.value = false;
    status.value = STATUS.SIGNED_OUT;
    accessToken.value = getLocalTokens().accessToken;
    refreshToken.value = getLocalTokens().refreshToken;
    refreshTokenPromise.value = undefined;

    if (sessionStorage) {
      sessionStorage.clear();
    }
    // clear impersonation from storage
    storageOriginalRefreshToken.value = null;
    storageOriginalWindowHref.value = null;
  }

  async function authenticated(tokens: Tokens) {
    $log.debug('Authenticated.');
    status.value = STATUS.SIGNED_IN;
    accessToken.value = tokens.accessToken;
    refreshToken.value = tokens.refreshToken;
    // const translationStore = useTranslationStore();
    // translationStore.fetchTranslationsAfterLogin(); // 2023-05-10 this is already done when setting the language the first time!
    const globalStore = useGlobalStore();
    globalStore.userSignedIn();
    // dispatch('translation/fetchTranslationsAfterLogin', null, { root: true }); // called from authenticated action
    try {
      const accountStore = useAccountStore();
      await accountStore.fetchAccountAndSettings();
      await PushNotification.signedIn();
      const socketStore = useSocketStore();
      socketStore.initialize(); // after fetching the account as we need the user ID
      // resolve();
    } catch (error) {
      $log.error('Error fetching profile:');
      // reject(error);
      throw error;
    } finally {
      //
    }
  }

  function setAccessTokenForJourneyAccess(token: string) {
    accessToken.value = token;
  }

  async function signIn(payload: {
    user: {
      username: string;
      password: string;
    };
  }) {
    // context: {dispatch: ƒ, commit: ƒ, getters: {…}, state: {…}, rootGetters: {…}, …}
    // payload: {user: {…}, requestOptions: {…}}
    status.value = STATUS.SIGNING_IN;
    try {
      interface AuthResponse {
        data: {
          type: string;
          exp: number;
          token: string;
          refresh: string;
        };
      }

      const response: undefined | AuthResponse = await $http?.post('/auth/login', { ...payload.user }, { signal: undefined });
      if (!response) {
        return;
      }
      // Save tokens (prefer cookies to local storage as local storage is accessible by JavaScript)
      const tokens = {
        accessToken: response.data.token,
        refreshToken: response.data.refresh,
      };
      setLocalTokensAndUpdateRequestHeader(tokens);
      $log.debug('Signed in. Will fetch account and settings, translations, and people and groups.');
      initialized.value = true; // make sure this is true, as it will be set to false when signing out
      await authenticated(tokens);
    } catch (error) {
      $log.warn('Error signing in:', error);
      clearLocalTokensAndUpdateRequestHeader(); // if the request fails, remove user tokens
      status.value = STATUS.ERROR;
      // reject(error);
      throw error;
    } finally {
      //
    }
  }

  async function signOut({ keepInSameRoute, refresh }: { keepInSameRoute?: boolean; refresh?: boolean } = {}) {
    $log.debug('Signing out.');
    status.value = STATUS.SIGNING_OUT;
    refreshToken.value = '';
    accessToken.value = '';
    await PushNotification.signedOut();
    try {
      if ($http?.defaults.headers.common && 'Authorization' in $http.defaults.headers.common) {
        await $http?.delete('/auth/logout', { signal: undefined });
      } else {
        // HTTP has no Authorization headers; skip the sign out server-side
      }
    } catch (error) {
      $log.error(error);
    } finally {
      clearLocalTokensAndUpdateRequestHeader(); // remove user tokens
      resetState();
      // Clear other store modules
      const accountStore = useAccountStore();
      accountStore.resetState();
      const globalStore = useGlobalStore();
      globalStore.resetState();
      const socketStore = useSocketStore();
      socketStore.resetState();
      const treasureQuestStore = useTreasureQuestStore();
      treasureQuestStore.resetState();
      if (refresh) {
        $log.debug('Sign out and refresh.');
        window.location.reload();
      } else if (!keepInSameRoute && router) {
        // Change route
        await router.push({ name: 'SignedOut' });
      }
      // resolve();
    }
  }

  function logErrorSignOutAndReject(message: string) {
    refreshTokenPromise.value = undefined;
    $log.error(message);
    signOut({ refresh: true });
    // return reject(new Error(message));
    throw new Error(message);
  }

  async function refreshTokenNow({ fetchProfile }: { fetchProfile: boolean }): Promise<Tokens> {
    const logTag = 'refreshTokenNow';
    $log.debug(`${logTag}`);
    //
    // Already refreshing?
    //
    if (refreshTokenPromise.value) {
      $log.debug(`${logTag}: already refreshing the token; returning the previous call that will have the new token when resolving.`);
      return refreshTokenPromise.value;
    }
    //
    // OK, let’s get a new token
    //
    const newRefreshTokenPromise = (async () => {
      try {
        interface ExchangeResponse {
          data: {
            token: string;
            type: 'Bearer';
          };
        }
        const response: undefined | ExchangeResponse = await $http?.put('/auth/exchange', { token: refreshToken.value }, { signal: undefined });
        if (response?.data && response.data.token) {
          const tokens: Tokens = {
            accessToken: response.data.token,
            refreshToken: refreshToken.value,
          };
          setLocalTokensAndUpdateRequestHeader(tokens);
          // const DEBUG_INVALID = false;
          // if (DEBUG_INVALID) {
          //   setTimeout(() => {
          //     const invalidTokens = {
          //       accessToken: 'Bearer DEBUG_INVALID',
          //       refreshToken: tokens.refreshToken,
          //     };
          //     setLocalTokensAndUpdateRequestHeader(invalidTokens);
          //     commit('AUTH_SUCCESS', invalidTokens);
          //   }, 5000);
          // }
          if (fetchProfile) {
            const accountStore = useAccountStore();
            const { userProfile } = accountStore;
            const userProfileExists = userProfile && Object.keys(userProfile).length > 0;
            if (!userProfileExists) {
              // User profile does not exist yet, wait for it.
              await accountStore.fetchAccountAndSettings();
            } else {
              // User profile exists, do not request it now (wait 1 second) to allow
              // the original request to repeat if the token was invalid
              setTimeout(() => {
                // Fetch account, as the user might have a new role
                //
                // Delay this (allow repeating the request first)
                // because the original request could be updating the account
                // e.g. changing the language, and this fetch would overwrite the change
                //
                accountStore.fetchAccountAndSettings();
              }, 1000);
            }
          }
          //
          // Resolve with the new access token
          //
          // return resolve(tokens.accessToken);
          return tokens;
        }
        //
        // No token in response
        //
        const message = `${logTag}: no token in response.`;
        logErrorSignOutAndReject(message);
        return {
          accessToken: '',
          refreshToken: '',
        };
      } catch (error) {
        const BAD_GATEWAY = 502 as const;
        if (typeof error === 'object'
          && error
          && 'response' in error
          && typeof error.response === 'object'
          && error.response
          && 'status' in error.response
          && error.response.status === BAD_GATEWAY) {
          // deploy in progress? No need to sign out.
          // throw error;
        }
        const message = `${logTag}: failed getting a new token, will sign the user out: ${error}`;
        logErrorSignOutAndReject(message);
        return {
          accessToken: '',
          refreshToken: '',
        };
      } finally {
        //
        // Done. Clear the promise.
        //
        refreshTokenPromise.value = undefined;
      }
    })();
    refreshTokenPromise.value = newRefreshTokenPromise;
    return newRefreshTokenPromise;
  }

  async function refreshTokenFirstTime() {
    return refreshTokenNow({ fetchProfile: false });
  }

  async function refreshTokenAfter401Unauthorized(requestUrl: string) {
    //
    // Failed on refresh?
    //
    if (requestUrl && requestUrl.endsWith('/auth/exchange')) {
      // Requesting to refresh the token because the previous token refresh request failed? Stop.
      const message = 'Will not try to refresh the token for a failed refresh token request.';
      logErrorSignOutAndReject(message);
      throw new Error(message);
    }
    return refreshTokenNow({ fetchProfile: true });
  }

  async function impersonateUser(id: number) {
    const accountStore = useAccountStore();
    if (!(accountStore.userRoleIsAdministrator || accountStore.userRoleIsImpLAndDManager)) {
      $log.warn('impersonateUser: current user is not Admin or L&D Manager');
      return;
    }
    try {
      interface ModuleResponse {
        data: {
          token: string;
          type: string;
        };
      }

      const response: undefined | ModuleResponse = await $http?.get(`/auth/impersonate/${id}`);
      if (response?.data?.token) {
        $log.info(`impersonateUser: will impersonate user ${id}`);
        storageOriginalRefreshToken.value = storageRefreshToken.value;
        storageOriginalWindowHref.value = window.location.href;
        storageRefreshToken.value = response.data.token;
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    } catch (error) {
      $log.debug('impersonateUser error:', error);
    } finally {
      //
    }
  }

  function exitImpersonate() {
    if (storageOriginalRefreshToken.value) {
      storageRefreshToken.value = storageOriginalRefreshToken.value;
    }
    storageOriginalRefreshToken.value = null;

    setTimeout(() => {
      if (storageOriginalWindowHref.value) {
        window.location.href = storageOriginalWindowHref.value;
        storageOriginalWindowHref.value = null;
      } else {
        window.location.reload();
      }
    }, 100);
  }

  async function initialize() {
    const tokens = getLocalTokens();
    if (tokens.refreshToken) {
      $log.debug('DEBUG 20190701: refresh token exists, will request a new access token');

      // await new Promise((resolve) => setTimeout(resolve, 10000));
      const newTokens: Tokens = await refreshTokenFirstTime();
      initialized.value = true; // to make the isAuthenticated true if there is an access token
      $log.debug('DEBUG 20190701: got new access token and fetched account, will now continue');
      // updateRequestHeaders(tokens.accessToken); // update or delete the Authorization
      if (accessToken.value) {
        // await new Promise(resolve => setTimeout(resolve, 10000));
        await authenticated(newTokens);
      } else {
        const keepInSameRoute = true;
        await signOut({ keepInSameRoute });
      }
    } else {
      $log.debug('DEBUG 20190701: no refreshToken; sign out');
      const keepInSameRoute = true;
      await signOut({ keepInSameRoute });
      initialized.value = true; // to make the isAuthenticated true if there is an access token
    }
    // No need to fetch the account here, as it is done on main.js the first time
  }

  return {
    //
    // State
    //
    initialized,
    status,
    accessToken,
    refreshToken,
    refreshTokenPromise,
    //
    // Getters
    //
    isAuthenticated,
    isSigningOut,
    isImpersonating,
    //
    // Actions
    //
    injectRouter,
    resetState,
    initialize,
    authenticated,
    setAccessTokenForJourneyAccess,
    // refreshTokenFirstTime,
    refreshTokenAfter401Unauthorized,
    refreshTokenNow,
    signIn,
    signOut,
    // logErrorSignOutAndReject,
    impersonateUser,
    exitImpersonate,
  };
});

export { useAuthStore };
