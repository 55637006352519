// Pinia Store
import { inject, ref } from 'vue';
// import type { App } from 'vue';
import { defineStore } from 'pinia';
import type { Router } from 'vue-router';
import type { ToastObject, ToastOptions, Toasted } from 'node_modules/@hoppscotch/vue-toasted/dist';

console.log('Pinia Toasts store is being created.'); // no access to Vue.prototype.$log here yet

let router: Router;

// Keys for toasts that can be updated
const TOAST_KEYS = {
  CONNECTION_LOST: 'CONNECTION_LOST',
  CONNECTION_LOST_BAD_GATEWAY: 'CONNECTION_LOST_BAD_GATEWAY',
  ACTIVE_HELP_REQUESTS: 'ACTIVE_HELP_REQUESTS',
};

function injectRouter(routerParam: Router) {
  router = routerParam;
}

const useToastsStore = defineStore('toasts', () => {
  // const router = useRouter(); // not working, will be injected via action
  const $log: any = inject('$log');
  const $toasted: Toasted | undefined = inject('$toasted');

  const toasts = ref<{
    [key: string]: ToastObject;
  }>({});

  // function injectApp(appParam: App) {
  //   app = appParam;
  //   $log.debug('toasts: injectApp OK');
  // }

  function showToast({ toastMessage, toastOptions = {} }: { toastMessage: string; toastOptions: ToastOptions }) {
    if (!$toasted) {
      $log.warn('showToast: no $toasted?');
      return null;
    }
    return $toasted.show(toastMessage, toastOptions);
  }

  function showOrUpdateToast({ toastKey, toastMessage, toastOptions = {} }: { toastKey: string; toastMessage: string; toastOptions: ToastOptions }) {
    if (!$toasted) {
      $log.warn('showOrUpdateToast: no $toasted?');
      return;
    }
    // const message = `Test toast: ${toastMessage}`;
    const message = toastMessage;
    if (toasts.value[toastKey]) {
      // toasts.value[toastKey].text(message); // replaces icon and actions!
      const textEl = toasts.value[toastKey].el.getElementsByClassName('toast-text-replaceable');
      if (textEl.length) {
        textEl[0].innerHTML = message;
      } else {
        $log.error(`Toasts store: showOrUpdateToast: no element found for class "toast-text-replaceable" on toast key ${toastKey}`, toasts.value[toastKey].el);
      }
    } else {
      // Does not exist. Create it
      // toast-text-replaceable is a workaround to avoid using text() (https://github.com/shakee93/vue-toasted/issues/100)
      const messageWithClass = `<div class="toast-text-replaceable">${message}</div>`;
      toasts.value[toastKey] = $toasted.show(messageWithClass, {
        ...toastOptions,
        onComplete: () => {
          // Toast dismissed / closed
          // console.log('xxxxx: toast onComplete!!');
          delete toasts.value[toastKey];
        },
      });
    }
  }

  function dismissToast(toastKey: string) {
    if (toasts.value[toastKey]) {
      toasts.value[toastKey].goAway(0);
      delete toasts.value[toastKey];
    } else {
      $log.error('Toasts store: dismissToast: no toast for key', toastKey);
    }
  }

  function connectionLost() {
    // Frontend / Internet down
    const toastMessage = 'Connection lost';
    const toastOptions = { type: 'error', icon: 'exclamation-circle' };
    showOrUpdateToast({ toastKey: TOAST_KEYS.CONNECTION_LOST, toastMessage, toastOptions });
  }
  function connectionLostBadGateway() {
    // Backend / API down
    const toastMessage = 'Connection lost. Please try again in a few moments.';
    const toastOptions = { type: 'error', icon: 'exclamation-circle' };
    showOrUpdateToast({ toastKey: TOAST_KEYS.CONNECTION_LOST_BAD_GATEWAY, toastMessage, toastOptions });
  }

  function activeHelpRequests({ count }: { count: number }) {
    if (!count || count < 0) {
      if (toasts.value[TOAST_KEYS.ACTIVE_HELP_REQUESTS]) {
        // Dismiss toast
        dismissToast(TOAST_KEYS.ACTIVE_HELP_REQUESTS);
        return;
      }
      // $log.error('Toasts store: activeHelpRequests: no count and no existing toast?');
      return;
    }
    //
    // Show or update toast
    //
    const toastMessage = `You have ${count} active help request(s).`;
    const toastOptions = {
      // className: isHelpRequest ? 'toast-urgent' : 'dark-toast',
      // className: 'toast-urgent',
      type: 'info',
      icon: 'info-circle',
      // theme: 'bubble', // ['toasted-primary' (default), 'outline', 'bubble']
      duration: 0,
      action: {
        text: 'Open',
        onClick: (_e: Event, toastObject: ToastObject) => {
          toastObject.goAway(0);
          router.push({ name: 'ReceivedHelpRequests' });
        },
      },
    };
    showOrUpdateToast({ toastKey: TOAST_KEYS.ACTIVE_HELP_REQUESTS, toastMessage, toastOptions });
  }

  function newHelpRequest({ sourceNickname, count, id }: { sourceNickname: string; count: number; id: number }) {
    // This will not be updatable (each new help request will result in a new toast)
    // Check if the "Active help requests" is open, and update the number if so
    // Show toast
    const toastMessage = `${sourceNickname} needs your help!`;
    const toastOptions = {
      // className: isHelpRequest ? 'toast-urgent' : 'dark-toast',
      // className: 'toast-urgent',
      type: 'info',
      icon: 'info-circle',
      // theme: 'bubble', // ['toasted-primary' (default), 'outline', 'bubble']
      duration: 0,
      action: {
        text: 'Open',
        onClick: (_e: Event, toastObject: ToastObject) => {
          toastObject.goAway(0);
          router.push({ name: 'ReceivedHelpRequests', query: { request: id } });
        },
      },
    };
    showToast({ toastMessage, toastOptions });
    //
    // Check existing "You have ${count} active help request(s)" toast
    //
    if (toasts.value[TOAST_KEYS.ACTIVE_HELP_REQUESTS]) {
      activeHelpRequests({ count }); // update value or dismiss it if count < zero
    }
  }

  function updateOrDismissActiveHelpRequestsToast({ count }: { count: number }) {
    //
    // Check existing "You have ${count} active help request(s)" toast
    //
    if (toasts.value[TOAST_KEYS.ACTIVE_HELP_REQUESTS]) {
      activeHelpRequests({ count }); // update value or dismiss it if count < zero
    }
  }

  return {
    //
    // State
    //
    toasts,
    //
    // Getters
    //

    //

    //
    // Actions
    //
    injectRouter,
    showToast,
    // showOrUpdateToast,
    // dismissToast,
    connectionLost,
    connectionLostBadGateway,
    activeHelpRequests,
    newHelpRequest,
    updateOrDismissActiveHelpRequestsToast,
  };
});

export { useToastsStore };
