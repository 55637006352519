(() => {
  console.log('xxxxx 2) CHECK CONFIG');

  const DIV_ID = 'invalid-config';
  const elem = document.getElementById(DIV_ID);
  if (!elem) {
    // console.log('20191127 checkConfig: stopping.');
    return;
  }
  // console.log('20191127 checkConfig: continuing');

  const errors: string[] = [];

  if (!import.meta.env.VITE_APP_NAME) {
    errors.push('No import.meta env.VITE_APP_NAME'); // <-- this gives an "Unexpected token" error!
  }

  // if (!window.ENV) {
  //   errors.push('No window.ENV');
  // } else {
  //   const requiredEnvs = [
  //     'FIREBASE',
  //   ];
  //
  //   requiredEnvs.forEach((requiredEnv) => {
  //     if (!(requiredEnv in window.ENV)) {
  //       errors.push(`Missing ${requiredEnv}`);
  //     }
  //   });
  // }

  //
  //
  //
  if (!errors.length) {
    return;
  }
  //
  //
  //

  let message = '<strong>Invalid configuration</strong>';

  errors.forEach((error) => {
    console.error('Bad configuration:', error);
    message += `<br>${error}`;
  });

  const elemMessage = document.createElement('div');
  elemMessage.innerHTML = message;
  const invalidConfigMessageDiv = document.getElementById('invalid-config-message');
  invalidConfigMessageDiv?.appendChild(elemMessage);
  const invalidConfigDiv = document.getElementById('invalid-config');
  if (invalidConfigDiv) {
    invalidConfigDiv.className += ' show';
  }

  function removeElementById(id: string) {
    const elemToBeDeleted = document.getElementById(id);
    if (!elemToBeDeleted) {
      console.error('No element with ID', id);
      return;
    }
    elemToBeDeleted.parentNode?.removeChild(elemToBeDeleted);
  }

  removeElementById('detect-invalid-ie');
  removeElementById('no-api');
  removeElementById('app');

  window.STOP_LOADING = true; // Vue will read this and will throw an Error on main.js
})();
