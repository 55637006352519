<template>
  <div :class="{ 'logo-at-header': forHeader, 'logo-at-talent-manual-cover': forTalentManual, 'logo-at-bottom-over-dark-background': forBottomPart }">
    <template v-if="forHeader || forTalentManual">
      <template v-if="globalStore.isAppZae">
        <img
          src="/img/zae/zae_logo.png"
          alt=""
        >
      </template>
      <template v-else-if="globalStore.isAppZly">
        <img
          src="/img/zly/ZLY_2021_Logo_Original_Full_01_Purple-White_008.svg"
          alt=""
        >
      </template>
      <template v-else-if="globalStore.isAppImp">
        <img
          src="/img/IMP_2020_C20_Logo_Original_008.png"
          alt=""
        >
      </template>
      <template v-else-if="globalStore.isAppJgo">
        <template v-if="globalStore.isOldThemeV2">
          <img
            src="/img/jgo/jgo-JGO_2019_CI_Logo_Original_Grey_SW_008.png"
            alt=""
          >
        </template>
        <template v-else-if="globalStore.isThemeV3">
          <img
            src="/img/jgo/JGO_2022_Logo_Original_Turquoise-White_008.png"
            alt=""
          >
        </template>
      </template>
      <template v-else-if="globalStore.isAppTly">
        <img
          src="/img/tly/TLY_2021_CI_Logo_original_008.svg"
          alt=""
        >
      </template>
    </template>
    <template v-else-if="forBottomPart">
      <div class="animated fadeIn">
        <template v-if="globalStore.isAppZly">
          <div class="logo">
            <img
              src="/img/zly/ZLY_2021_Logo_Original_Full_01_Purple-White_008.svg"
              alt=""
            >
          </div>
        </template>
        <template v-else-if="globalStore.isAppImp">
          <div class="logo">
            <img
              src="/img/IMP_2020_C20_Logo_Original_008.png"
              alt=""
            >
          </div>
        </template>
        <template v-else-if="globalStore.isAppJgo">
          <div class="logo">
            <img
              src="/img/jgo/jgo-Jaagou-AG-Logo-Weiss.png"
              alt=""
            >
          </div>
        </template>
        <template v-else-if="globalStore.isAppTly">
          <div class="logo">
            <img
              src="/img/tly/TLY_2021_CI_Logo_original_008.svg"
              alt=""
            >
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      [check config]
    </template>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { useGlobalStore } from '@/stores/global';

// import AppSvg from '@/components/misc/AppSvg.vue';

defineProps({
  forHeader: {
    type: Boolean,
    default: false,
  },
  forBottomPart: {
    type: Boolean,
    default: false,
  },
  forTalentManual: {
    type: Boolean,
    default: false,
  },
});

const globalStore = useGlobalStore();
</script>

<style
  lang="scss"
  scoped
>
.logo-at-header {
  height: 90%;

  svg {
    position: absolute;
    height: 90%;
    fill: $dark;
  }

  img {
    height: 100%;
  }

  .app-imp & {
    margin-right: 9.6vw; // the size of the logo
  }

  .app-jgo & {
    height: 100%;
  }

  .app-tly & {
    height: 70%;
  }

  .app-zly & {
    height: 70%;
    margin-right: 0;
  }

  .theme-v3e & {
    margin-right: unset;
    height: calc(#{$header-height} / 2 * 1.19);
    font-size: 0;
    line-height: 0;
  }
}

.logo-at-talent-manual-cover {
  height: 1.6rem;
  font-size: 0;
  line-height: 0;

  svg {
    //position: absolute;
    //height: 100%;
  }

  img {
    height: 100%;
  }

  .app-imp &,
  .app-tly & {
    //height: 1.4rem;
    width: 22.08%; // % of the page width inside the margins
    height: unset;

    img {
      width: 100%;
    }
  }

  .app-jgo & {
    height: 2.8rem;
  }
}

.logo-at-bottom-over-dark-background {
  align-self: center;

  .logo {
    img {
      height: 100%;
    }

    .app-imp & {
      height: 2.8vw;
      fill: #fff;
    }

    .app-jgo & {
      height: 4.5vw;
    }

    .app-tly & {
      height: 2.8vw;
    }

    .app-zly & {
      height: 2.8vw;
    }
  }
}
</style>
