const ALL_ROLES = {
  // 1  Administrator
  // 2  (IMP) L&D Manager (was "Dispatcher", was "Head PX" in Aug 2020)
  // 3  (IMP) Team Leader (was "Pilot")
  // 4  (IMP) Team Member (was "Crew Member")
  // 5  (IMP) Consultant
  // 6  (IMP) Co-Team Leader (was "Co-Pilot")
  // 7  Talent Manual
  // 8  (JGO) Partner
  // 9  (JGO) Participant
  // 10 (TLY) HR
  // 11 (TLY) Team Leader
  // 12 (TLY) Candidate
  // 13 (TLY) HR Admin (was HR Business Partner in Oct 2021)
  // 20 (TLY) Team Member
  NONE: {
    id: -1,
    code: 'none',
    name: 'No role',
  },
  ADMIN: {
    id: 1,
    code: 'admin',
    name: 'Administrator',
  },
  //
  // Imp:
  //
  IMP_L_AND_D_MANAGER: {
    id: 2,
    code: 'dispatcher',
    name: 'L&D Manager',
  },
  IMP_TEAM_LEADER: {
    id: 3,
    code: 'a-user',
    name: 'Team Leader',
  },
  IMP_TEAM_MEMBER: {
    id: 4,
    code: 'b-user',
    name: 'Team Member',
  },
  IMP_CONSULTANT: {
    id: 5,
    code: 'consultant',
    name: 'Consultant',
  },
  IMP_CO_TEAM_LEADER: {
    id: 6,
    code: 'co-pilot',
    name: 'Co-Team Leader',
  },
  TALENT_MANUAL: {
    id: 7,
    code: 'talent-manual',
    name: 'Talent Manual',
  },
  //
  // Jgo:
  //
  JGO_PARTNER: {
    id: 8,
    code: 'partner',
    name: 'Partner',
  },
  JGO_PARTICIPANT: {
    id: 9,
    code: 'participant',
    name: 'Participant',
  },
  //
  // Tly:
  //
  TLY_HR: {
    id: 10,
    code: 'hr',
    name: 'HR',
  },
  TLY_TEAM_LEADER: {
    id: 11,
    code: 'team-leader',
    name: 'Team Leader',
  },
  TLY_CANDIDATE: {
    id: 12,
    code: 'candidate',
    name: 'Candidate',
  },
  TLY_HR_ADMIN: {
    id: 13,
    code: 'hr-business-partner',
    name: 'HR Admin',
  },
  TLY_TEAM_MEMBER: {
    id: 20,
    code: 'team-member',
    name: 'Team Member',
  },
  //
  //
  //
  ZLY_TEAM_LEADER: {
    id: 31,
    code: 'team-leader',
    name: 'Team Leader',
  },
  ZLY_TEAM_MEMBER: {
    id: 32,
    code: 'team-member',
    name: 'Team Member',
  },
} as const;

const INVITATION_STATUS = {
  created: 1,
  sent: 2,
  activated: 3,
  expired: 4,
  revoked: 5,
  cancelled: 6,
  rejected: 7,
  pending: 8,
  failed: 9,
} as const;

const CO_PILOT_REQUEST_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
  CANCELLED: 4,
} as const;

const QUESTIONNAIRE_REQUEST_STATUS = {
  PENDING: 1,
  APPROVED: 2,
  REJECTED: 3,
} as const;

const IMPULSE_STATUS = {
  ACTIVE: 1,
  REFLECTED: 2,
  POSTPONED: 3,
  SKIPPED: 4,
  EXPIRED: 5,
} as const;

const HELP_REQUEST_STATUS = {
  PENDING: 1,
  // DONE: 2,
  CANCELLED: 4,
  RESOLVED: 5,
  POSTPONED: 6,
} as const;

const USER_JOURNEY_TALENT_MANUAL_PAGES = {
  PHASE_1: {
    WELCOME: 1004,
    REGISTRATION: 1006,
  },
  PHASE_2: {
    ACCOUNT_COMPLETION: 2081,
  },
  PHASE_3: {
    TALENT_MANUAL_BENEFITS: 3010,
    TALENT_MANUAL_SYSTEM_ELEMENTS: 3020,
    TALENT_MANUAL_SYSTEM_ELEMENTS_FIRE: 3021,
    TALENT_MANUAL_SYSTEM_ELEMENTS_AIR: 3022,
    TALENT_MANUAL_SYSTEM_ELEMENTS_WATER: 3023,
    TALENT_MANUAL_SYSTEM_ELEMENTS_EARTH: 3024,
    TALENT_MANUAL_BENEFITS_YOU_AND_CREW: 3030,
    TALENT_MANUAL_ARE_YOU_READY: 3040,
    QUESTIONNAIRE_LANGUAGE: 3050,
    QUESTIONNAIRE_INSTRUCTIONS: 3051,
    QUESTIONNAIRE: 3052,
    QUESTIONNAIRE_UNKNOWN_ERROR: 3055,
    RESPONSIBILITY: 3060,
    // KNOWLEDGE_CHECK_QUIZ: 3070, // DEACTIVATED until there is content
    TALENT_MANUAL_PRE_QUALITY_CHECK: 3075,
    TALENT_MANUAL_QUALITY_CHECK_LANGUAGE: 3079,
    TALENT_MANUAL_QUALITY_CHECK: 3080,
    PRIVATE_EMAIL: 3085,
    AVATARS_ELEMENTS: 3090,
    AVATARS_ELEMENTS_FIRE: 3091,
    AVATARS_ELEMENTS_AIR: 3092,
    AVATARS_ELEMENTS_WATER: 3093,
    AVATARS_ELEMENTS_EARTH: 3094,
  },
  PHASE_4: {
    CONGRATULATIONS: 4080,
  },
} as const;

const USER_JOURNEY_V3_PAGES_IMP = {
  // Format vpxxx: version 3, phase 1, page 1 = 31001
  PHASE_1: {
    //
  },
  PHASE_2: {
    // TODO: move each for the correct phase?
    // TODO: fix first digit to be the phase number?
    P1_WELCOME: 1001,
    P1_THE_FOUR_JOURNEY_PHASES: 1002,
    P1_CREATE_YOUR_ACCOUNT: 1003,
    P1_COMPLETE_YOUR_ACCOUNT: 1004,
    P1_HELLO_START_USING: 2001,
    P1_PHASE_1_COMPLETED: 2002,
    P2_EVERYBODY_PLAYS_AND_WINS: 2003,
    P2_CREATE_YOUR_OWN_TALENT_MANUAL: 3049,
    P2_QUESTIONNAIRE_LANGUAGE: 3050,
    P2_QUESTIONNAIRE_INSTRUCTIONS: 3051,
    P2_QUESTIONNAIRE: 3052,
    P2_QUESTIONNAIRE_UNKNOWN_ERROR: 3055,
    P2_RESPONSIBILITY: 3060,
    P2_TALENT_MANUAL_PRE_QUALITY_CHECK: 3075,
    P2_TALENT_MANUAL_QUALITY_CHECK_LANGUAGE: 3079,
    P2_TALENT_MANUAL_QUALITY_CHECK: 3080,
    P2_TALENT_MANUAL_RESULT: 3081,
    P2_TALENT_MANUAL_SUMMARY: 3083,
    P2_SAVE_PRIVATE_EMAIL: 3085,
    P2_PHASE_2_COMPLETED: 4001,
    P3_YOUR_IMPULZITY_WORLD: 4002,
    P3_YIEC_IMPULSE_CATEGORIES: 4003,
    P3_YIEC_IMPULSE_TYPES: 4004,
    P3_YIEC_IMPULSE_LEARNING_AND_DOING: 4009,
    P3_YIEC_THE_INDIVIDUAL_EMPOWERMENT_MODEL: 4011,
    P3_YIEC_PRODUCTIVITY_AND_HAPPINESS: 4012,
    P3_YIEC_THIS_IS_YOUR_IE_COMPANION: 4013,
    P3_YIEC_FLOW_OVERVIEW: 4014,
    P3_FROM_WHAT_TO_WHY: 4015,
    P3_PHASE_3_COMPLETED: 4050,
    P4_ANYTIME_ANYWHERE: 4060,
    P4_GET_YOUR_IMPULZITY_APP: 4070,
    P4_PHASE_4_COMPLETED: 4080,
  },
  PHASE_3: {
    //
  },
  PHASE_4: {
    //
  },
} as const;

const USER_JOURNEY_V3_PAGES_JGO = {
  // Format vpxxx: version 3, phase 1, page 1 = 31001
  PHASE_1: {
    //
  },
  PHASE_2: {
    // TODO: move each for the correct phase?
    // TODO: fix first digit to be the phase number?
    P1_WELCOME: 1001,
    P1_THE_FOUR_JOURNEY_PHASES: 1002,
    P1_CREATE_YOUR_ACCOUNT: 1003,
    P1_COMPLETE_YOUR_ACCOUNT: 1004,
    P1_HELLO_START_USING: 2001,
    P1_PHASE_1_COMPLETED: 2002,
    P2_EVERYBODY_PLAYS_AND_WINS: 2003,
    P2_CREATE_YOUR_OWN_TALENT_MANUAL: 3049,
    P2_QUESTIONNAIRE_LANGUAGE: 3050,
    P2_QUESTIONNAIRE_INSTRUCTIONS: 3051,
    P2_QUESTIONNAIRE: 3052,
    P2_QUESTIONNAIRE_UNKNOWN_ERROR: 3055,
    P2_RESPONSIBILITY: 3060,
    P2_TALENT_MANUAL_PRE_QUALITY_CHECK: 3075,
    P2_TALENT_MANUAL_QUALITY_CHECK_LANGUAGE: 3079,
    P2_TALENT_MANUAL_QUALITY_CHECK: 3080,
    P2_TALENT_MANUAL_RESULT: 3081,
    P2_TALENT_MANUAL_SUMMARY: 3083,
    P2_SAVE_PRIVATE_EMAIL: 3085,
    P2_PHASE_2_COMPLETED: 4001,
    P3_YOUR_JGO_WORLD: 4002, // Different from IMP
    P3_EXPERT_TALK: 4003, // Different from IMP
    P3_FITTING_MODEL: 4004, // Different from IMP
    P3_FIND_TREASURE: 4005, // Different from IMP
    P3_FITTING_SYSTEM: 4006, // Different from IMP
    P3_FROM_WHAT_TO_WHY: 4015,
    P3_SOFT_SKILLS_LEARNING_MODULE: 4016, // Different from IMP
    P3_PHASE_3_COMPLETED: 4050,
    // P4_ANYTIME_ANYWHERE: 4060,
    P4_GET_YOUR_JAAGOU_APP: 4070,
    P4_PHASE_4_COMPLETED: 4080,
  },
  PHASE_3: {
    //
  },
  PHASE_4: {
    //
  },
} as const;

const USER_JOURNEY_V3_PAGES_TLY = {
  // Format vpxxx: version 3, phase 1, page 1 = 31001
  PHASE_1: {
    //
  },
  PHASE_2: {
    // TODO: move each for the correct phase?
    // TODO: fix first digit to be the phase number?
    P1_WELCOME: 1001,
    P1_THE_FOUR_JOURNEY_PHASES: 1002,
    P1_CREATE_YOUR_ACCOUNT: 1003,
    P1_COMPLETE_YOUR_ACCOUNT: 1004,
    P1_HELLO_START_USING: 2001,
    P1_PHASE_1_COMPLETED: 2002,
    P2_EVERYBODY_PLAYS_AND_WINS: 2003,
    P2_CREATE_YOUR_OWN_TALENT_MANUAL: 3049,
    P2_QUESTIONNAIRE_LANGUAGE: 3050,
    P2_QUESTIONNAIRE_INSTRUCTIONS: 3051,
    P2_QUESTIONNAIRE: 3052,
    P2_QUESTIONNAIRE_UNKNOWN_ERROR: 3055,
    P2_RESPONSIBILITY: 3060,
    P2_TALENT_MANUAL_PRE_QUALITY_CHECK: 3075,
    P2_TALENT_MANUAL_QUALITY_CHECK_LANGUAGE: 3079,
    P2_TALENT_MANUAL_QUALITY_CHECK: 3080,
    P2_TALENT_MANUAL_RESULT: 3081,
    P2_TALENT_MANUAL_SUMMARY: 3083,
    P2_SAVE_PRIVATE_EMAIL: 3085,
    P2_PHASE_2_COMPLETED: 4001,
    P3_YOUR_TLY_WORLD: 4002, // Different from IMP
    P3_EXPERT_TALK: 4003, // Different from IMP
    P3_FITTING_MODEL: 4004, // Different from IMP
    P3_FIND_TREASURE: 4005, // Different from IMP
    P3_FITTING_SYSTEM: 4006, // Different from IMP
    P3_FROM_WHAT_TO_WHY: 4015,
    P3_SOFT_SKILLS_LEARNING_MODULE: 4016, // Different from IMP
    P3_PHASE_3_COMPLETED: 4050,
    // P4_ANYTIME_ANYWHERE: 4060,
    P4_GET_YOUR_TREAZRLY_APP: 4070,
    P4_PHASE_4_COMPLETED: 4080,
  },
  PHASE_3: {
    //
  },
  PHASE_4: {
    //
  },
} as const;

// export type GetReadyProgramImp = typeof USER_JOURNEY_V3_PAGES_IMP.PHASE_2;
// export type GetReadyProgramJgo = typeof USER_JOURNEY_V3_PAGES_JGO.PHASE_2;
// export type GetReadyProgramTly = typeof USER_JOURNEY_V3_PAGES_TLY.PHASE_2;

const appName = import.meta.env.VITE_APP_NAME;

let USER_JOURNEY_V3_PAGES: typeof USER_JOURNEY_V3_PAGES_IMP | typeof USER_JOURNEY_V3_PAGES_JGO | typeof USER_JOURNEY_V3_PAGES_TLY;
if (appName === 'imp') {
  USER_JOURNEY_V3_PAGES = USER_JOURNEY_V3_PAGES_IMP;
} else if (appName === 'jgo') {
  USER_JOURNEY_V3_PAGES = USER_JOURNEY_V3_PAGES_JGO;
} else if (appName === 'tly' || appName === 'zae') {
  USER_JOURNEY_V3_PAGES = USER_JOURNEY_V3_PAGES_TLY;
} else {
  // TODO: this is a fallback to prevent the app failing if using theme v3 (router tries to read PHASE_2 from undefined)
  USER_JOURNEY_V3_PAGES = USER_JOURNEY_V3_PAGES_IMP;
}

const ISO_ALPHA2_TO_AIRCRAFT_REGISTRATION_PREFIXES_MAP = {
  // ISO Alpha-2: http://www.nationsonline.org/oneworld/country_code_list.htm
  // Prefixes: https://en.wikipedia.org/wiki/List_of_aircraft_registration_prefixes
  AF: { countryOrRegion: 'Afghanistan', registrationPrefix: 'YA' },
  AL: { countryOrRegion: 'Albania', registrationPrefix: 'ZA' },
  DZ: { countryOrRegion: 'Algeria', registrationPrefix: '7T' },
  AD: { countryOrRegion: 'Andorra', registrationPrefix: 'C3' },
  AO: { countryOrRegion: 'Angola', registrationPrefix: 'D2' },
  AI: { countryOrRegion: 'Anguilla', registrationPrefix: 'VP-A' },
  AG: { countryOrRegion: 'Antigua and Barbuda', registrationPrefix: 'V2' },
  AR: { countryOrRegion: 'Argentina', registrationPrefix: 'LV' },
  // AR: { countryOrRegion: 'Argentina', registrationPrefix: 'LQ' }, // Government/Police
  AM: { countryOrRegion: 'Armenia', registrationPrefix: 'EK' },
  AW: { countryOrRegion: 'Aruba', registrationPrefix: 'P4' },
  AU: { countryOrRegion: 'Australia', registrationPrefix: 'VH' },
  AT: { countryOrRegion: 'Austria', registrationPrefix: 'OE' },
  AZ: { countryOrRegion: 'Azerbaijan', registrationPrefix: '4K' },
  BS: { countryOrRegion: 'Bahamas', registrationPrefix: 'C6' },
  BH: { countryOrRegion: 'Bahrain', registrationPrefix: 'A9C' },
  BD: { countryOrRegion: 'Bangladesh', registrationPrefix: 'S2' },
  BB: { countryOrRegion: 'Barbados', registrationPrefix: '8P' },
  BY: { countryOrRegion: 'Belarus', registrationPrefix: 'EW' },
  BE: { countryOrRegion: 'Belgium', registrationPrefix: 'OO' },
  BZ: { countryOrRegion: 'Belize', registrationPrefix: 'V3' },
  BJ: { countryOrRegion: 'Benin', registrationPrefix: 'TY' },
  BM: { countryOrRegion: 'Bermuda', registrationPrefix: 'VP-B' }, // VP-B, VQ-B
  BT: { countryOrRegion: 'Bhutan', registrationPrefix: 'A5' },
  BO: { countryOrRegion: 'Bolivia', registrationPrefix: 'CP' },
  BA: { countryOrRegion: 'Bosnia and Herzegovina', registrationPrefix: 'T9' },
  // BA: { countryOrRegion: 'Bosnia and Herzegovina', registrationPrefix: 'E7' },
  BW: { countryOrRegion: 'Botswana', registrationPrefix: 'A2' },
  BR: { countryOrRegion: 'Brazil', registrationPrefix: 'PP' },
  // BR: { countryOrRegion: 'Brazil', registrationPrefix: 'PR' },
  // BR: { countryOrRegion: 'Brazil', registrationPrefix: 'PS' },
  // BR: { countryOrRegion: 'Brazil', registrationPrefix: 'PT' },
  // BR: { countryOrRegion: 'Brazil', registrationPrefix: 'PU' },
  VG: { countryOrRegion: 'British Virgin Islands', registrationPrefix: 'VP-L' },
  BN: { countryOrRegion: 'Brunei', registrationPrefix: 'V8' },
  BG: { countryOrRegion: 'Bulgaria', registrationPrefix: 'LZ' },
  BF: { countryOrRegion: 'Burkina Faso', registrationPrefix: 'XT' },
  BI: { countryOrRegion: 'Burundi', registrationPrefix: '9U' },
  KH: { countryOrRegion: 'Cambodia', registrationPrefix: 'XU' },
  CM: { countryOrRegion: 'Cameroon', registrationPrefix: 'TJ' },
  CA: { countryOrRegion: 'Canada', registrationPrefix: 'C' },
  CV: { countryOrRegion: 'Cape Verde', registrationPrefix: 'D4' },
  KY: { countryOrRegion: 'Cayman Islands', registrationPrefix: 'VP-C' },
  CF: { countryOrRegion: 'Central African Republic', registrationPrefix: 'TL' },
  TD: { countryOrRegion: 'Chad', registrationPrefix: 'TT' },
  CL: { countryOrRegion: 'Chile', registrationPrefix: 'CC' },
  CN: { countryOrRegion: 'China', registrationPrefix: 'B' },
  // CO: { countryOrRegion: 'Colombia', registrationPrefix: 'HJ' }, // Microlights
  CO: { countryOrRegion: 'Colombia', registrationPrefix: 'HK' },
  KM: { countryOrRegion: 'Comoros', registrationPrefix: 'D6' },
  CD: { countryOrRegion: 'Congo, Democratic Republic of', registrationPrefix: '9S' },
  CG: { countryOrRegion: 'Congo, Republic of', registrationPrefix: 'TN' },
  CK: { countryOrRegion: 'Cook Islands', registrationPrefix: 'E5' },
  CR: { countryOrRegion: 'Costa Rica', registrationPrefix: 'TI' },
  HR: { countryOrRegion: 'Croatia', registrationPrefix: '9A' },
  CU: { countryOrRegion: 'Cuba', registrationPrefix: 'CU' },
  CY: { countryOrRegion: 'Cyprus, Republic of', registrationPrefix: '5B' },
  CZ: { countryOrRegion: 'Czech Republic', registrationPrefix: 'OK' },
  CI: { countryOrRegion: 'Côte d’Ivoire', registrationPrefix: 'TU' },
  DK: { countryOrRegion: 'Denmark', registrationPrefix: 'OY' },
  DJ: { countryOrRegion: 'Djibouti', registrationPrefix: 'J2' },
  DM: { countryOrRegion: 'Dominica', registrationPrefix: 'J7' },
  DO: { countryOrRegion: 'Dominican Republic', registrationPrefix: 'HI' },
  TL: { countryOrRegion: 'East Timor', registrationPrefix: '4W' },
  EC: { countryOrRegion: 'Ecuador', registrationPrefix: 'HC' },
  EG: { countryOrRegion: 'Egypt', registrationPrefix: 'SU' },
  SV: { countryOrRegion: 'El Salvador', registrationPrefix: 'YS' },
  GQ: { countryOrRegion: 'Equatorial Guinea', registrationPrefix: '3C' },
  ER: { countryOrRegion: 'Eritrea', registrationPrefix: 'E3' },
  EE: { countryOrRegion: 'Estonia', registrationPrefix: 'ES' },
  ET: { countryOrRegion: 'Ethiopia', registrationPrefix: 'ET' },
  FK: { countryOrRegion: 'Falkland Islands', registrationPrefix: 'VP-F' },
  FO: { countryOrRegion: 'Faroe Islands', registrationPrefix: 'See Denmark' },
  FJ: { countryOrRegion: 'Fiji Islands', registrationPrefix: 'DQ' },
  FI: { countryOrRegion: 'Finland', registrationPrefix: 'OH' },
  FR: { countryOrRegion: 'France', registrationPrefix: 'F' },
  GF: { countryOrRegion: 'French Guyana', registrationPrefix: 'F-O' },
  GP: { countryOrRegion: 'French West Indies', registrationPrefix: 'F-OG' }, // Guadeloupe?
  GA: { countryOrRegion: 'Gabon', registrationPrefix: 'TR' },
  GM: { countryOrRegion: 'Gambia', registrationPrefix: 'C5' },
  GE: { countryOrRegion: 'Georgia', registrationPrefix: '4L' },
  DE: { countryOrRegion: 'Germany', registrationPrefix: 'D' },
  GH: { countryOrRegion: 'Ghana', registrationPrefix: '9G' },
  GI: { countryOrRegion: 'Gibraltar', registrationPrefix: 'VP-G' },
  GR: { countryOrRegion: 'Greece', registrationPrefix: 'SX' },
  GL: { countryOrRegion: 'Greenland', registrationPrefix: 'See Denmark' },
  GD: { countryOrRegion: 'Grenada', registrationPrefix: 'J3' },
  GT: { countryOrRegion: 'Guatemala', registrationPrefix: 'TG' },
  GG: { countryOrRegion: 'Guernsey', registrationPrefix: '2' },
  GN: { countryOrRegion: 'Guinea', registrationPrefix: '3X' },
  GW: { countryOrRegion: 'Guinea Bissau', registrationPrefix: 'J5' },
  GY: { countryOrRegion: 'Guyana', registrationPrefix: '8R' },
  HT: { countryOrRegion: 'Haiti', registrationPrefix: 'HH' },
  HN: { countryOrRegion: 'Honduras', registrationPrefix: 'HR' },
  HK: { countryOrRegion: 'Hong Kong', registrationPrefix: 'B-H' }, // B-H, B-K, B-L
  HU: { countryOrRegion: 'Hungary', registrationPrefix: 'HA' },
  IS: { countryOrRegion: 'Iceland', registrationPrefix: 'TF' },
  IN: { countryOrRegion: 'India', registrationPrefix: 'VT' },
  ID: { countryOrRegion: 'Indonesia', registrationPrefix: 'PK' },
  IR: { countryOrRegion: 'Iran', registrationPrefix: 'EP' },
  IQ: { countryOrRegion: 'Iraq', registrationPrefix: 'YI' },
  IE: { countryOrRegion: 'Ireland', registrationPrefix: 'EI' },
  IM: { countryOrRegion: 'Isle of Man', registrationPrefix: 'M' },
  IL: { countryOrRegion: 'Israel', registrationPrefix: '4X' },
  IT: { countryOrRegion: 'Italy', registrationPrefix: 'I' },
  JM: { countryOrRegion: 'Jamaica', registrationPrefix: '6Y' },
  JP: { countryOrRegion: 'Japan', registrationPrefix: 'JA' },
  JE: { countryOrRegion: 'Jersey', registrationPrefix: 'ZJ' },
  JO: { countryOrRegion: 'Jordan', registrationPrefix: 'JY' },
  KZ: { countryOrRegion: 'Kazakhstan', registrationPrefix: 'UP' },
  KE: { countryOrRegion: 'Kenya', registrationPrefix: '5Y' },
  KI: { countryOrRegion: 'Kiribati', registrationPrefix: 'T3' },
  KP: { countryOrRegion: 'Korea, People’s Democratic Rep.', registrationPrefix: 'P' },
  KR: { countryOrRegion: 'Korea, Republic of', registrationPrefix: 'HL' },
  XK: { countryOrRegion: 'Kosovo, Republic of', registrationPrefix: 'Z6' },
  KW: { countryOrRegion: 'Kuwait', registrationPrefix: '9K' },
  KG: { countryOrRegion: 'Kyrgyzstan', registrationPrefix: 'EX' },
  LA: { countryOrRegion: 'Laos', registrationPrefix: 'RDPL' },
  LV: { countryOrRegion: 'Latvia', registrationPrefix: 'YL' },
  LB: { countryOrRegion: 'Lebanon', registrationPrefix: 'OD' },
  LS: { countryOrRegion: 'Lesotho', registrationPrefix: '7P' },
  LR: { countryOrRegion: 'Liberia', registrationPrefix: 'A8' },
  LY: { countryOrRegion: 'Libya', registrationPrefix: '5A' },
  LI: { countryOrRegion: 'Liechtenstein', registrationPrefix: 'HB' },
  LT: { countryOrRegion: 'Lithuania', registrationPrefix: 'LY' },
  LU: { countryOrRegion: 'Luxembourg', registrationPrefix: 'LX' },
  MO: { countryOrRegion: 'Macau', registrationPrefix: 'B-M' },
  MK: { countryOrRegion: 'Macedonia, Republic of', registrationPrefix: 'Z3' },
  MG: { countryOrRegion: 'Madagascar', registrationPrefix: '5R' },
  MW: { countryOrRegion: 'Malawi', registrationPrefix: '7Q' },
  MY: { countryOrRegion: 'Malaysia', registrationPrefix: '9M' },
  MV: { countryOrRegion: 'Maldives', registrationPrefix: '8Q' },
  ML: { countryOrRegion: 'Mali', registrationPrefix: 'TZ' },
  MT: { countryOrRegion: 'Malta', registrationPrefix: '9H' },
  MH: { countryOrRegion: 'Marshall Islands', registrationPrefix: 'V7' },
  MR: { countryOrRegion: 'Mauritania', registrationPrefix: '5T' },
  MU: { countryOrRegion: 'Mauritius', registrationPrefix: '3B' },
  // MX: { countryOrRegion: 'Mexico', registrationPrefix: 'XA' }, // Commercial
  MX: { countryOrRegion: 'Mexico', registrationPrefix: 'XB' }, // Private
  // MX: { countryOrRegion: 'Mexico', registrationPrefix: 'XC' }, // Government
  FM: { countryOrRegion: 'Micronesia, Federated States of', registrationPrefix: 'V6' },
  MD: { countryOrRegion: 'Moldova', registrationPrefix: 'ER' },
  MC: { countryOrRegion: 'Monaco', registrationPrefix: '3A' },
  MN: { countryOrRegion: 'Mongolia', registrationPrefix: 'JU' },
  ME: { countryOrRegion: 'Montenegro', registrationPrefix: '4O' },
  MS: { countryOrRegion: 'Montserrat', registrationPrefix: 'VP-M' },
  MA: { countryOrRegion: 'Morocco', registrationPrefix: 'CN' },
  MZ: { countryOrRegion: 'Mozambique', registrationPrefix: 'C9' },
  MM: { countryOrRegion: 'Myanmar', registrationPrefix: 'XY' },
  // MM: { countryOrRegion: 'Myanmar', registrationPrefix: 'XZ' }, // Not used
  NA: { countryOrRegion: 'Namibia', registrationPrefix: 'V5' },
  NR: { countryOrRegion: 'Nauru', registrationPrefix: 'C2' },
  NP: { countryOrRegion: 'Nepal', registrationPrefix: '9N' },
  NL: { countryOrRegion: 'Netherlands', registrationPrefix: 'PH' },
  AN: { countryOrRegion: 'Netherlands Antilles', registrationPrefix: 'PJ' },
  NZ: { countryOrRegion: 'New Zealand', registrationPrefix: 'ZK' },
  NI: { countryOrRegion: 'Nicaragua', registrationPrefix: 'YN' },
  NE: { countryOrRegion: 'Niger', registrationPrefix: '5U' },
  NG: { countryOrRegion: 'Nigeria', registrationPrefix: '5N' },
  NO: { countryOrRegion: 'Norway', registrationPrefix: 'LN' },
  OM: { countryOrRegion: 'Oman', registrationPrefix: 'A4O' },
  PK: { countryOrRegion: 'Pakistan', registrationPrefix: 'AP' },
  // Temporary assignment of Egyptian prefix:
  // PS: { countryOrRegion: 'Palestine', registrationPrefix: 'SU-Y' },
  PS: { countryOrRegion: 'Palestine', registrationPrefix: 'E4' },
  PA: { countryOrRegion: 'Panama', registrationPrefix: 'HP' },
  PG: { countryOrRegion: 'Papua New Guinea', registrationPrefix: 'P2' },
  PY: { countryOrRegion: 'Paraguay', registrationPrefix: 'ZP' },
  PE: { countryOrRegion: 'Peru', registrationPrefix: 'OB' },
  PH: { countryOrRegion: 'Philippines', registrationPrefix: 'RP' },
  PL: { countryOrRegion: 'Poland', registrationPrefix: 'SP' }, // SN: police / border guard
  PT: { countryOrRegion: 'Portugal', registrationPrefix: 'CS' }, // CR, CS
  QA: { countryOrRegion: 'Qatar', registrationPrefix: 'A7' },
  RO: { countryOrRegion: 'Romania', registrationPrefix: 'YR' },
  RU: { countryOrRegion: 'Russian Federation', registrationPrefix: 'RA' },
  // state-owned aircraft:
  // RU: { countryOrRegion: 'Russian Federation', registrationPrefix: 'RF' },
  RW: { countryOrRegion: 'Rwanda', registrationPrefix: '9XR' },
  RE: { countryOrRegion: 'Réunion Island', registrationPrefix: 'F-OD' },
  SH: { countryOrRegion: 'Saint Helena/Ascension', registrationPrefix: 'VQ-H' },
  KN: { countryOrRegion: 'Saint Kitts and Nevis', registrationPrefix: 'V4' },
  LC: { countryOrRegion: 'Saint Lucia', registrationPrefix: 'J6' },
  VC: { countryOrRegion: 'Saint Vincent and the Grenadines', registrationPrefix: 'J8' },
  WS: { countryOrRegion: 'Samoa', registrationPrefix: '5W' },
  SM: { countryOrRegion: 'San Marino', registrationPrefix: 'T7' },
  SA: { countryOrRegion: 'Saudi Arabia', registrationPrefix: 'HZ' },
  SN: { countryOrRegion: 'Senegal', registrationPrefix: '6V' },
  RS: { countryOrRegion: 'Serbia', registrationPrefix: 'YU' },
  SC: { countryOrRegion: 'Seychelles', registrationPrefix: 'S7' },
  SL: { countryOrRegion: 'Sierra Leone', registrationPrefix: '9L' },
  SG: { countryOrRegion: 'Singapore', registrationPrefix: '9V' },
  SK: { countryOrRegion: 'Slovakia', registrationPrefix: 'OM' },
  SI: { countryOrRegion: 'Slovenia', registrationPrefix: 'S5' },
  SB: { countryOrRegion: 'Solomon Islands', registrationPrefix: 'H4' },
  SO: { countryOrRegion: 'Somalia', registrationPrefix: '6O' },
  ZA: { countryOrRegion: 'South Africa', registrationPrefix: 'ZS' }, // type certified aircraft
  // rotorcraft / civil RPAS:
  // ZA: { countryOrRegion: 'South Africa', registrationPrefix: 'ZT' },
  // non-type certified aircraft:
  // ZA: { countryOrRegion: 'South Africa', registrationPrefix: 'ZU' },
  SS: { countryOrRegion: 'South Sudan', registrationPrefix: 'Z8' },
  ES: { countryOrRegion: 'Spain', registrationPrefix: 'EC' },
  LK: { countryOrRegion: 'Sri Lanka', registrationPrefix: '4R' },
  SD: { countryOrRegion: 'Sudan', registrationPrefix: 'ST' },
  SR: { countryOrRegion: 'Surinam', registrationPrefix: 'PZ' },
  SZ: { countryOrRegion: 'Swaziland', registrationPrefix: '3D' },
  SE: { countryOrRegion: 'Sweden', registrationPrefix: 'SE' },
  CH: { countryOrRegion: 'Switzerland', registrationPrefix: 'HB' },
  SY: { countryOrRegion: 'Syria', registrationPrefix: 'YK' },
  ST: { countryOrRegion: 'São Tomé and Príncipe', registrationPrefix: 'S9' },
  PF: { countryOrRegion: 'Tahiti', registrationPrefix: 'F-OH' }, // French Polynesia
  TW: { countryOrRegion: 'Taiwan', registrationPrefix: 'B' },
  TJ: { countryOrRegion: 'Tajikistan', registrationPrefix: 'EY' },
  TZ: { countryOrRegion: 'Tanzania', registrationPrefix: '5H' },
  TH: { countryOrRegion: 'Thailand', registrationPrefix: 'HS' },
  TG: { countryOrRegion: 'Togo', registrationPrefix: '5V' },
  TO: { countryOrRegion: 'Tonga', registrationPrefix: 'A3' },
  TT: { countryOrRegion: 'Trinidad and Tobago', registrationPrefix: '9Y' },
  TN: { countryOrRegion: 'Tunisia', registrationPrefix: 'TS' },
  TR: { countryOrRegion: 'Turkey', registrationPrefix: 'TC' },
  TM: { countryOrRegion: 'Turkmenistan', registrationPrefix: 'EZ' },
  TC: { countryOrRegion: 'Turks and Caicos', registrationPrefix: 'VQ-T' },
  TV: { countryOrRegion: 'Tuvalu', registrationPrefix: 'T2' },
  UG: { countryOrRegion: 'Uganda', registrationPrefix: '5X' },
  UA: { countryOrRegion: 'Ukraine', registrationPrefix: 'UR' },
  AE: { countryOrRegion: 'United Arab Emirates', registrationPrefix: 'A6' },
  GB: { countryOrRegion: 'United Kingdom', registrationPrefix: 'G' },
  // XX: { countryOrRegion: 'United Nations', registrationPrefix: '4U' },
  US: { countryOrRegion: 'United States of America', registrationPrefix: 'N' },
  UY: { countryOrRegion: 'Uruguay', registrationPrefix: 'CX' },
  UZ: { countryOrRegion: 'Uzbekistan', registrationPrefix: 'UK' },
  VU: { countryOrRegion: 'Vanuatu', registrationPrefix: 'YJ' },
  VE: { countryOrRegion: 'Venezuela', registrationPrefix: 'YV' },
  VN: { countryOrRegion: 'Vietnam', registrationPrefix: 'VN' },
  YE: { countryOrRegion: 'Yemen', registrationPrefix: '7O' },
  ZM: { countryOrRegion: 'Zambia', registrationPrefix: '9J' },
  ZW: { countryOrRegion: 'Zimbabwe', registrationPrefix: 'Z' },
} as const;

const CANDIDATE_APPLICATION_STATUS = {
  PENDING: 1,
  INVITED: 2,
  SELECTION: 3,
  REJECTED: 4, // Retracted
  SELECTED: 5,
  REVOKED: 6, // Refused
  HIRED: 7,
  TO_BE_HIRED: 8,
  NEGOTIATION: 9,
  READY_TO_BE_INVITED: 10,
  INVITATION_SCHEDULED: 11,
  INVITATION_SENT: 12,
  TEAM_MEMBER_JOINED: 13,
} as const;

const CANDIDATE_HIRING_STEP = {
  PENDING: 1,
  FIT: 2,
  INTERVIEW: 3,
  NEGOTIATION: 4,
  NOT_HIRED: 5,
  HIRED: 6,
  REJECTED: 7,
} as const;

const JOB_STEPS = {
  SETUP: 1, // Job configuration.
  DIGGING: 2, // Digging steps.
  REFINEMENT: 3, // Job is under a refinement process which means that is being reviewed in order to get the max precision of the candidate talents.
  JOB_AD: 4, // Job ad is being created.
} as const;

const JOB_STATUS = {
  CREATED: 1, // Job created and under configuration
  SELECTION: 2, // Team Leader inviting candidates
  PROCESSING: 3, // Team Leader choosing the candidate
  CLOSED: 4, // Job is closed
} as const;

export default {
  ALL_ROLES,
  INVITATION_STATUS,
  CO_PILOT_REQUEST_STATUS,
  QUESTIONNAIRE_REQUEST_STATUS,
  IMPULSE_STATUS,
  HELP_REQUEST_STATUS,
  USER_JOURNEY_TALENT_MANUAL_PAGES,
  USER_JOURNEY_V3_PAGES,
  ISO_ALPHA2_TO_AIRCRAFT_REGISTRATION_PREFIXES_MAP,
  CANDIDATE_APPLICATION_STATUS,
  CANDIDATE_HIRING_STEP,
  JOB_STEPS,
  JOB_STATUS,
};
