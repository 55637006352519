// Pinia Store
import { defineStore } from 'pinia';

import { inject, ref } from 'vue';
import type { AxiosStatic } from 'axios';

import type { ClientWithCustomFields } from '@/components/management/ClientDetails.vue';
// import type { CancelToken, CancelTokenSource, Canceler } from 'axios';

console.log('Pinia API store is being created.'); // no access to Vue.prototype.$log here yet

export interface ClientDetailsRaw {
  accounts: {
    total: number;
  };
  address_line1: string;
  address_line2: string | null;
  address_line3: string | null;
  avatar: string;
  certificate_achievement_threshold?: number; // IMP only, "After this many completed impulses, the Personal Development Certificate is achieved"
  city: string | null;
  code: string;
  country: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  created_at: string;
  created_by: number;
  custom_impulse_frequency?: number; // IMP only, "After this many default impulses, a custom impulse will be shown"
  display_name: string;
  email: string;
  id: number;
  inserted_at: string;
  is_consulting: boolean;
  name: string;
  percentage_team_leaders: null | number; // null on IMP and JGO?
  phone: string;
  total_employees: null | number; // null on IMP and JGO?
  website: string | null;
  zip_code: string | null;
}

interface ClientAccountRawGet {
  client: {
    display_name: string;
    id: number;
    is_consulting: boolean;
  };
  description: null | string;
  display_name: string;
  id: number;
  invitations: {
    total_dispatchers: 0;
  };
  license: { // Imp
    nr_licenses: number;
    nr_licenses_real_people: null;
    nr_licenses_user: number;
  } | { // Jgo
    balance: number;
  } | { // Tly
    subscription: null | { // might come without this on PUT if the initial POST had no license info
      inserted_at: string;
      license_end_date: string;
      license_start_date: string;
      updated_at: string;
    };
  };
  nr_licenses: number;
  nr_licenses_real_people: null;
  nr_licenses_user: number;
}

interface ClientAccountResponse {
  data: ClientAccountRawGet;
}

export type AccountReturn = ClientAccountRawGet & {
  nr_licences: number; // with c instead of s
  nr_licences_user: number; // with c instead of s
};

export interface CountryItem {
  aircraft_code: string;
  code: string;
  id: number;
  name: string;
}

export interface UsersListItem {
  account_display_name: string | null;
  account_id: number | null;
  activated_at: string;
  active: boolean;
  avatar: string;
  client: null | ClientDetailsRaw;
  client_display_name: string | null;
  client_id: number | null;
  deleted: boolean;
  email: string;
  full_name: string;
  has_talent_manual: boolean;
  id: number;
  last_activity_datetime: string;
  locked: boolean;
  mask: string;
  native_language: {
    id: number;
    name: string;
  } | null;
  native_language_id: number | null;
  native_language_name: string | null;
  nickname: string;
  onboarding_step: number;
  registered_at: string;
  roles: {
    id: number;
    name: string;
  }[];
}

export interface SimpleUserRaw {
  avatar: string;
  email: string;
  first_name: string;
  has_talent_manual: boolean;
  id: number;
  is_avatar: boolean;
  last_name: string;
  mask: string;
  nickname: string;
  position: null | string;
}

export const useApiStore = defineStore('api', () => {
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const pendingRequests = ref<{ [key: string]: AbortController }>({}); // Define the cancel queue

  function addRequestToCancelQueue(payload: { url: string; controller: AbortController }) {
    if (payload.url) {
      pendingRequests.value[payload.url] = payload.controller;
    }
    // console.log(`[DEBUG] addRequestToCancelQueue: added ${payload.url}; map is now`, pendingRequests.value);
  }

  function deleteRequestFromCancelQueue(payload: { url: string; abort: boolean }) {
    // check if pendingRequests contains our request URL
    if (payload.url && pendingRequests.value[payload.url]) {
      // if we want to abort ongoing call, abort it
      if (payload.abort) {
        pendingRequests.value[payload.url].abort();
      }
      // remove the request URL from pending requests
      delete pendingRequests.value[payload.url];
      // console.log('[DEBUG] deleteRequestFromCancelQueue: removed', payload, 'map is now', pendingRequests.value);
    } else {
      console.log('deleteRequestFromCancelQueue: request url', payload.url, 'not found in pending requests');
    }
  }

  function cancelAllRequestsInQueue() {
    // console.log('[DEBUG] cancelAllRequestsInQueue: map was', pendingRequests.value);
    Object.entries(pendingRequests.value).forEach(([, controller]) => {
      // cancel(token.toString()); // token will be the error message, used to delete it from the queue
      controller.abort();
    });
    pendingRequests.value = {};
    // console.log('[DEBUG] cancelAllRequestsInQueue: map is now', pendingRequests.value);
  }

  async function apiFetchClient(payload: number): Promise<ClientWithCustomFields> {
    $log.debug('apiFetchClient');

    // try {
    interface ClientResponse {
      data: ClientDetailsRaw;
    }

    const response: undefined | ClientResponse = await $http?.get(`/clients/${payload}`);
    if (response?.data) {
      const client = response.data;
      return {
        ...response.data,
        countryId: client.country && client.country.id,
        displayName: client.display_name,
        addressLine1: client.address_line1,
        addressLine2: client.address_line2,
        addressLine3: client.address_line3,
        zipCode: client.zip_code,
        customImpulseFrequency: client.custom_impulse_frequency,
        customMinimumCertificateRequirement: client.certificate_achievement_threshold,
      };
    }
    throw new Error('No data in response');
    // } catch (error) {
    //   throw error;
    // }
  }

  async function apiFetchClientAccount({ clientId, accountId }: { clientId: string; accountId: string }): Promise<AccountReturn> {
    $log.debug('apiFetchClientAccount');
    // try {
    const response: undefined | ClientAccountResponse = await $http?.get(`/clients/${clientId}/accounts/${accountId}`);
    if (response?.data) {
      const account = response.data;
      return {
        ...response.data,
        nr_licences: account.nr_licenses, // with 's' on API
        nr_licences_user: account.nr_licenses_user, // with 's' on API
      };
    }
    throw new Error('No data in response');
    // } catch (error) {
    //   throw error;
    // }
  }

  return {
    pendingRequests,
    addRequestToCancelQueue,
    deleteRequestFromCancelQueue,
    cancelAllRequestsInQueue,
    apiFetchClient,
    apiFetchClientAccount,
  };
});
