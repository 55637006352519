import { type Locale, endOfDay, format, formatDistance, formatDistanceToNowStrict, parseISO, startOfDay } from 'date-fns';
import { de, enGB } from 'date-fns/locale';

interface Locales {
  'en-GB': Locale;
  'de-DE': Locale;
}

const locales: Locales = {
  'en-GB': enGB,
  'de-DE': de,
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
function formatDate(date: Date | string, formatStr: string = 'PP', locale: string = 'en-GB') {
  return format(typeof date === 'string' ? parseISO(date) : date, formatStr, {
    locale: locales[locale as keyof Locales],
  });
}

function formatDistanceLocalized(date: Date, baseDate: Date, locale: string = 'en-GB') {
  return formatDistance(date, baseDate, {
    addSuffix: true, // "ago" if date is before baseDate, or "in" prefix if date is after baseDate
    locale: locales[locale as keyof Locales],
  });
}

function formatDistanceToNowStrictLocalized(date: Date, locale: string = 'en-GB') {
  return formatDistanceToNowStrict(date, {
    addSuffix: true, // "ago" if date is before baseDate, or "in" prefix if date is after baseDate
    locale: locales[locale as keyof Locales],
  });
}

/**
 * Returns a string with two dates, to be used at the backend filter
 * @param date1 start date, that will be at 00:00:00
 * @param date2 end date, that will be at 23:59:59
 * @returns a string with the two dates separated by a comma, e.g. "2023-01-01 00:00:00,2023-12-31 23:59:59"
 */
function getDatesStrForFilter(date1: Date, date2: Date) {
  const formatStr = 'yyyy-MM-dd HH:mm:ss';
  return `${format(startOfDay(date1), formatStr)},${format(endOfDay(date2), formatStr)}`;
}

export {
  formatDate,
  formatDistanceLocalized,
  formatDistanceToNowStrictLocalized,
  getDatesStrForFilter,
};
