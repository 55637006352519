<template>
  <div
    :class="{ 'mb-gap-0_38x': !noBottomMargin }"
    class="w-100"
  >
    <Field
      v-slot="{ errors, field, value }"
      :model-value="innerValue"
      :rules="rules"
      :name="name"
      :label="validationCustomLabel || label"
      @update:model-value="(val: any) => $emit('update:modelValue', val)"
    >
      <!--      <div>-->
      <!--        <div>-->
      <!--          >> modelValue {{ modelValue }}-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          >> innerValue {{ innerValue }}-->
      <!--        </div>-->
      <!--        &lt;!&ndash;        <div>&ndash;&gt;-->
      <!--        &lt;!&ndash;          >> field {{ field }}&ndash;&gt;-->
      <!--        &lt;!&ndash;        </div>&ndash;&gt;-->
      <!--        <div>-->
      <!--          >> value {{ value }}-->
      <!--        </div>-->
      <!--        &lt;!&ndash;        <button&ndash;&gt;-->
      <!--        &lt;!&ndash;          type="button"&ndash;&gt;-->
      <!--        &lt;!&ndash;          @click="testSetInnerValue"&ndash;&gt;-->
      <!--        &lt;!&ndash;        >&ndash;&gt;-->
      <!--        &lt;!&ndash;          test set innerValue&ndash;&gt;-->
      <!--        &lt;!&ndash;        </button>&ndash;&gt;-->
      <!--      </div>-->
      <div class="d-flex">
        <BFormGroup
          :class="[groupClasses, { 'is-invalid': errors && errors.length, 'required': label && showLabel && isRequired }]"
          class="mb-0 flex-grow-1"
        >
          <template #label>
            <slot name="label">
              <template v-if="showLabel && label">
                {{ label }}
              </template>
            </slot>
          </template>
          <slot
            name="form-input-inside-form-group"
            :errors="errors"
            :field="field"
            :value="value"
          >
            <template v-if="inputType === 'input'">
              <BFormInput
                v-bind="{ ...field, ...Object.fromEntries(Object.entries($attrs).filter(([key]) => key !== 'class')) }"
                :id="inputId"
                ref="inputRef"
                :model-value="value"
                :placeholder="placeholder"
                :type="type"
                :class="inputClasses"
                :tabindex="tabindex"
                :disabled="disabled"
                :readonly="readonly"
                @blur="$emit('blur', $event)"
                @keyup="(evt: Event) => $emit('keyup', evt)"
              />
            </template>
            <template v-if="inputType === 'text-area'">
              <BFormTextarea
                v-bind="{ ...field, ...Object.fromEntries(Object.entries($attrs).filter(([key]) => key !== 'class')) }"
                ref="inputRef"
                :model-value="value"
                :placeholder="placeholder"
                :type="type"
                :tabindex="tabindex"
                :disabled="disabled"
                :readonly="readonly"
                @blur="$emit('blur', $event)"
              />
              <!--                @update:model-value="(val) => innerValue = val"-->
            </template>
          </slot>
        </BFormGroup>
        <slot name="right-of-input" />
      </div>
      <slot
        name="before-invalid-feedback"
        :errors="errors"
        :field="field"
      />
      <ErrorMessage
        v-if="showErrors"
        v-slot="{ message }"
        :name="name"
      >
        <BFormInvalidFeedback
          :class="{ 'm-0': !(errors && errors.length) }"
          class="d-block"
        >
          <div>
            <slot name="custom-error" />
          </div>
          {{ message }}
        </BFormInvalidFeedback>
      </ErrorMessage>
      <slot name="after-invalid-feedback" />
    </Field>
  </div>
</template>

<script
  setup
  lang="ts"
>
import { type PropType, computed, ref } from 'vue';
import { ErrorMessage, Field, type RuleExpression } from 'vee-validate';
import type { InputType } from 'bootstrap-vue-next';
import { BFormInput, BFormTextarea } from 'bootstrap-vue-next';

const props = defineProps({
  rules: {
    // we need this
    type: String as PropType<RuleExpression<unknown>>,
    default: '',
  },
  modelValue: {
    type: [String, Number, Object] as PropType<any>,
    default: null,
  },
  type: {
    type: String as PropType<InputType>,
    default: 'text',
  },
  name: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: null,
  },
  showLabel: {
    type: Boolean,
    default: true,
  },
  validationCustomLabel: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  inputType: {
    type: String,
    default: 'input',
  },
  groupClasses: {
    type: String,
    default: '',
  },
  inputClasses: {
    type: String,
    default: '',
  },
  inputId: {
    type: String,
    default: null,
  },
  noBottomMargin: {
    type: Boolean,
    default: false,
  },
  tabindex: {
    type: String,
    default: '',
  },
  showErrors: {
    type: Boolean,
    default: true,
  },
});

defineEmits<{
  'blur': [event: Event];
  'keyup': [event: Event];
  'update:modelValue': [value: any];
}>();

const inputRef = ref<InstanceType<typeof BFormInput | typeof BFormTextarea> | null>(null);
const innerValue = computed(() => props.modelValue);

const isRequired = computed(() => {
  if (!props.rules) {
    return false;
  }
  if (typeof props.rules === 'string') {
    return props.rules.includes('required');
  }
  if (typeof props.rules === 'object') {
    return 'required' in props.rules && props.rules.required === true;
  }
  return false;
});

defineExpose({
  inputRef,
});
</script>
