// Pinia Store
import { computed, inject, ref } from 'vue';
// import type { App } from 'vue';
import type { AxiosStatic } from 'axios';
import { defineStore } from 'pinia';

import globalConstants from '@/globalConstants';

// let app: App;

const {
  JOB_STATUS,
} = globalConstants;

console.log('Pinia TreasureQuest store is being created.'); // no access to Vue.prototype.$log here yet

export interface Job {
  checks: {
    company_check: string; // e.g., 'not_started', 'completed'
    job_check: {
      id: number;
      name: string;
    };
    leader_check: string; // e.g., 'not_started','completed'
    team_check: {
      current_team: number;
      job_id: number;
      status: string; // e.g., 'started', 'processed'
      user_answer_id: null;
    };
  };
  cycle_date: null | string; // datetime
  description: null | string;
  entity: {
    id: number; // used, e.g., to know which Talent Manuals area we can access
  };
  hiring_date: null | string; // datetime
  id: number;
  inserted_at: string;
  notes_count: number;
  starting_date: null | string; // datetime
  status: {
    id: number;
    name: string;
  };
  step: {
    id: number;
    name: string;
  };
  title: string;
  user: { // the job owner
    avatar: string;
    first_name: string;
    has_talent_manual: boolean;
    id: number;
    is_avatar: boolean;
    last_name: string;
    mask: string;
    nickname: string;
  };
}

const useTreasureQuestStore = defineStore('treasureQuest', () => {
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const jobs = ref<Job[]>([]);

  const openJobs = computed(() => jobs.value.filter((job) => job.status.id !== JOB_STATUS.CLOSED));
  const closedJobs = computed(() => jobs.value.filter((job) => job.status.id === JOB_STATUS.CLOSED));

  function resetState() {
    jobs.value = [];
  }

  // function injectApp(appParam: App) {
  //   app = appParam;
  //   $log.debug('treasureQuest: injectApp OK');
  // }

  async function fetchJobs() {
    try {
      // this.loadingJobs = true;
      interface JobsResponse {
        data: {
          data: Job[];
          paginate: null;
        };
      }
      const response: undefined | JobsResponse = await $http?.get('/jobs', { signal: undefined });
      if (!response) {
        return;
      }
      jobs.value = response.data.data
        .sort((a, b) => b.id - a.id); // Sort by ID desc
    } catch (error) {
      $log.error(error);
    } finally {
      // this.loadingJobs = false;
    }
  }

  return {
    //
    // State
    //
    jobs,
    //
    // Getters
    //
    openJobs,
    closedJobs,
    //
    // Actions
    //
    resetState,
    fetchJobs,
  };
});

export { useTreasureQuestStore };
