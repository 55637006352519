import type { App } from 'vue';
import { useTranslationStore } from '@/stores/translation';
import type { JobCandidateAndOrder, JobList, JobSubList } from '@/views/tly/TreasureQuest.vue';

let app: App | undefined;
let log: {
  debug: (...data: any[]) => void;
  info: (...data: any[]) => void;
  warn: (...data: any[]) => void;
  error: (...data: any[]) => void;
  fatal: (...data: any[]) => void;
} | undefined;

function injectApp(appParam: App) {
  app = appParam;
  log = app.config.globalProperties.$log;
}

function getPrintableCommaSeparatedErrors(errors: any) {
  let allErrors = '';
  Object.keys(errors)
    .forEach((key) => {
      const keyWithoutUnderscores = key.replace('_', ' ');
      let value = errors[key];
      if (Array.isArray(value)) {
        value = value.join(', ');
      } else if (errors[key] && typeof errors[key] === 'object') {
        value = JSON.stringify(errors[key]);
      }
      allErrors = `${allErrors ? `${allErrors}, ` : ''}${keyWithoutUnderscores}: ${value}`;
    });
  return allErrors;
}

function getFromItemTranslations(item: any, translationsKey: string, itemKey: string, overrideLocale?: string) {
  const translationStore = useTranslationStore();

  if (!item || !translationsKey || !itemKey) {
    log?.warn('getFromItemTranslations: missing parameter(s) (item, translationsKey, itemKey):', item, translationsKey, itemKey);
    return '';
  }

  if (!item[translationsKey]) {
    log?.warn('getFromItemTranslations: item does not have translationsKey (item, translationsKey):', item, translationsKey);
    return '';
  }

  if (!translationStore.supportedLocales) {
    log?.error('getFromItemTranslations: supportedLocales not imported?');
    return '';
  }

  const foundLocale = translationStore.supportedLocales.find((locale: { code: string }) => locale.code === (overrideLocale || translationStore.localeCode));
  if (!foundLocale) {
    log?.error('getFromItemTranslations: no locale found');
    return '';
  }

  if (!(Array.isArray(item[translationsKey]) || (item[translationsKey] && typeof item[translationsKey] === 'object'))) {
    log?.warn('getFromItemTranslations: item[translationsKey] is not an array nor an object (item, translationsKey):', item, translationsKey);
    return '';
  }

  let translationsForCurrentLocale;
  if (Array.isArray(item[translationsKey])) {
    translationsForCurrentLocale = item[translationsKey].find((translation: { language_id: number }) => translation.language_id === foundLocale.id);
  } else if (item[translationsKey] && typeof item[translationsKey] === 'object') {
    translationsForCurrentLocale = item[translationsKey][foundLocale.id];
  }

  if (!translationsForCurrentLocale) {
    log?.warn('getFromItemTranslations: no translations found for current locale', foundLocale);
    return '';
  }

  if (!translationsForCurrentLocale[itemKey]) {
    log?.warn('getFromItemTranslations: no translations found for itemKey', itemKey);
    return '';
  }

  return translationsForCurrentLocale[itemKey];

  // Object from edit/create, with keys = locale ID
  // const translationsForCurrentLocale = this.itemProgram.translations[foundLocale.id];
  // return (translationsForCurrentLocale && translationsForCurrentLocale[itemKey]) || '';
}

function getNewItemOrderAfterMoving(list: any[], newIndex: number, orderKey: string = 'order_value') {
  //
  // After we drag the card to the position we want
  //
  log?.debug(`useUtils: getNewItemOrderAfterMoving: list order [${list.map((li) => li[orderKey])}], newIndex ${newIndex}, orderKey ${orderKey}`);
  // get new order value
  let previousItemOrder = 0;
  let nextItemOrder = 0;

  // newIndex is +1 if dragged to the end?!
  let index = newIndex;
  if (index >= list.length) {
    index = list.length - 1;
  }

  let newOrderValue;
  if (index > 0) {
    // is NOT the first element!
    if (orderKey in list[index - 1]) {
      previousItemOrder = list[index - 1][orderKey];
    } else {
      log?.warn(`useUtils: orderKey ${orderKey} does not exist in the list`);
    }
  } else {
    // is the first element! check the next
  }
  if (index + 1 < list.length) {
    // is NOT the last element!
    if (orderKey in list[index + 1]) {
      nextItemOrder = list[index + 1][orderKey];
    } else {
      log?.warn(`useUtils: orderKey ${orderKey} does not exist in the list`);
    }
    newOrderValue = (previousItemOrder + nextItemOrder) / 2;
  } else {
    // is the last element! (if there was no previous, new order will be 0, and the old order will be kept)
    newOrderValue = previousItemOrder * 2;
  }

  log?.debug(`useUtils: getNewItemOrderAfterMoving: [previous: ${previousItemOrder}, this ${newOrderValue}, next ${nextItemOrder}], index ${index}`);

  return {
    previousItemOrder,
    nextItemOrder,
    newOrderValue,
    index,
  };
}

function getAllCandidatesFromLists(listsRoot: JobList[]) {
  const candidates: JobCandidateAndOrder[] = [];
  const findCandidates = (lists: (JobList | JobSubList)[]) => {
    lists.forEach((list) => {
      // log?.debug('getAllCandidatesFromLists: findCandidateAndUpdateNotesCount: list:', list);
      const jobCandidateLists = list.job_candidate_lists;
      if (jobCandidateLists) {
        jobCandidateLists.forEach((jcl) => {
          candidates.push(jcl);
        });
      }
      if (list.sub_lists) {
        findCandidates(list.sub_lists);
      }
    });
  };
  findCandidates(listsRoot);
  return candidates;
}

export function useUtils() {
  return {
    injectApp,

    wait: (ms: number) => new Promise((resolve) => setTimeout(resolve, ms)),
    getPrintableCommaSeparatedErrors,
    getFromItemTranslations,
    getNewItemOrderAfterMoving,
    getAllCandidatesFromLists,
  };
}
