function getDateObjInLocalTime(date: Date | string | number) {
  if (date instanceof Date) {
    return date;
  }

  if (typeof date === 'string' && date.includes('T') && !date.includes('Z')) {
    // date is like '2023-03-30T09:17:34', we need to add the Z for the new Date() to create a local date (convert UTC to local)
    return new Date(`${date}Z`);
  }

  return new Date(date);
}

export function useDates() {
  return {
    getDateObjInLocalTime,
  };
}
