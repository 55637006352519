/// <reference path="../src/types">

(() => {
  console.log('xxxxx 5) CHECK API CONNECTIVITY');

  const DIV_ID = 'no-api';
  const elem = document.getElementById(DIV_ID);
  if (!elem) {
    // console.log('20191127 checkAPI: stopping.');
    return;
  }

  // console.log('20191127 checkAPI: continuing');

  function removeElementById(id: string) {
    const elemToBeDeleted = document.getElementById(id);
    if (!elemToBeDeleted) {
      console.error('No element with ID', id);
      return;
    }
    elemToBeDeleted.parentNode?.removeChild(elemToBeDeleted);
  }

  function gotError(status: number | string) {
    const noApiDiv = document.getElementById(DIV_ID);
    if (noApiDiv) {
      noApiDiv.className += ' show';
    }
    const noApiStatusDiv = document.getElementById('no-api-status');
    if (noApiStatusDiv) {
      noApiStatusDiv.innerHTML = status.toString();
    }
    // Remove other elements (keep detect-invalid-ie)
    removeElementById('app-preload');
    removeElementById('app');
    window.STOP_LOADING = true; // Vue will read this and will throw an Error on main.js
  }

  function gotOK() {
    removeElementById(DIV_ID); // not needed anymore
    // gotError('TEST'); // DEBUG ONLY
  }

  function testConnection(url: string) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);

    xhr.timeout = 1000 * 20; // 20 seconds

    xhr.onload = function onLoad() {
      if (xhr.status === 200) {
        gotOK();
      } else {
        gotError(xhr.status);
      }
    };

    xhr.onerror = function onError() {
      gotError(xhr.status);
    };

    xhr.ontimeout = function onTimeout() {
      // XMLHttpRequest timed out. Do something here.
      gotError('Timed out.');
    };

    xhr.send();
  }

  const workOffline = window.ENV && window.ENV.WORK_OFFLINE;
  const shouldCheckConnection = !workOffline;

  if (shouldCheckConnection) {
    testConnection(`/api/${(window.ENV && window.ENV.API_VERSION) || 'v1'}/version`);
  }
})();
