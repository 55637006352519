import * as Sentry from '@sentry/vue';
import axios from 'axios';
import type { App, ComponentCustomProperties } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useAccountStore } from '@/stores/account';
import { useGlobalStore } from '@/stores/global';

const APP_ID = '2290075c-a21c-11e8-84e1-ec21e5082f22';

let app: App;
let clientIp: string;

interface Options {
  // optional : defaults to true if not specified
  isEnabled: boolean;
  // required ['debug', 'info', 'warn', 'error', 'fatal']
  logLevel: string;
  // optional : defaults to false if not specified
  stringifyArguments: boolean;
  // optional : defaults to false if not specified
  showLogLevel: boolean;
  // optional : defaults to false if not specified
  showMethodName: boolean;
  // optional : defaults to '|' if not specified
  separator: string;
  // optional : defaults to false if not specified
  showConsoleColors: boolean;
}

type ReplacerFn = (key: string, value: string) => string;

export default (() => {
  const defaultOptions = {
    isEnabled: true,
    logLevel: 'debug',
    separator: '|',
    stringifyArguments: false,
    showLogLevel: false,
    showMethodName: false,
    showConsoleColors: false,
  };

  const logLevelsArray = ['debug', 'info', 'warn', 'error', 'fatal'];

  function serializer(replacer?: ReplacerFn, cycleReplacerArg?: ReplacerFn) {
    const stack: unknown[] = [];
    const keys: string[] = [];

    const customCycleReplacer = (key: string, value: string) => {
      if (stack[0] === value) {
        return '[Circular ~]';
      }
      return `[Circular ~.${keys.slice(0, stack.indexOf(value)).join('.')}]`;
    };
    const cycleReplacer = cycleReplacerArg || customCycleReplacer;

    return function (this: (replacer?: ReplacerFn, cycleReplacerArg?: ReplacerFn) => void, key: string, value: string) {
      // ! this is always undefined?
      if (stack.length > 0) {
        const thisPos = stack.indexOf(this);

        ~thisPos ? stack.splice(thisPos + 1) : stack.push(this);
        ~thisPos ? keys.splice(thisPos, Infinity, key) : keys.push(key);
        if (~stack.indexOf(value)) {
          value = cycleReplacer.call(this, key, value);
        }
      } else {
        stack.push(value);
      }

      return replacer ? replacer.call(this, key, value) : value;
    };
  }

  function stringifySafe(obj: any, replacer?: ReplacerFn, spaces?: string, cycleReplacer?: ReplacerFn): string {
    // https://github.com/moll/json-stringify-safe/blob/master/stringify.js
    return JSON.stringify(obj, serializer(replacer, cycleReplacer), spaces);
  }

  function getMethodName() {
    let error: unknown = null;
    try {
      throw new Error('-');
    } catch (e) {
      error = e;
    }
    // IE9 does not have .stack property
    if (!(typeof error === 'object' && error && 'stack' in error && error.stack)) {
      return '(no error.stack)';
    }
    if (typeof error.stack !== 'string') {
      return '(error.stack is not a string)';
    }
    const fourthLine = error.stack.split('\n')[3];
    let fullMethodName;
    let methodName;
    let path;
    if (/ /.test(fourthLine)) {
      [, fullMethodName, path] = fourthLine.trim().split(' ');
      // 0: "at"
      // 1: "Proxy.handler"
      // 2: "(https://localhost:8093/src/components/message-center/MessageCenter.vue?t=1693392856427:42:19)"
    } else {
      fullMethodName = fourthLine;
    }
    if (fullMethodName && fullMethodName.includes('.')) {
      [, methodName] = fullMethodName.split('.'); // remove the "Proxy." part, get only the method name
    } else {
      methodName = fullMethodName;
    }
    if (path) {
      return `${methodName} ${path}`;
    }
    return `${methodName} (unknown component)`;
  }

  async function printLogMessage(
    logLevel: null | string = null,
    logLevelPrefix: string = '',
    methodNamePrefix: string = '',
    formattedArguments: unknown[] = [],
    showConsoleColors = false,
  ) {
    if (showConsoleColors && (logLevel === 'warn' || logLevel === 'error' || logLevel === 'fatal')) {
      console[logLevel === 'fatal' ? 'error' : logLevel](logLevelPrefix, methodNamePrefix, ...formattedArguments);
    } else {
      console.log(logLevelPrefix, methodNamePrefix, ...formattedArguments);
    }

    if (!formattedArguments) {
      return;
    }

    if (formattedArguments.length && formattedArguments[0] === 'Connection lost?') {
      // do not report
      return;
    }

    let isHttpCancel = false;
    formattedArguments.forEach((arg) => {
      if (axios.isCancel(arg)) {
        isHttpCancel = true; // will not report

        if (window.ENV
          && window.ENV.ENVIRONMENT
          && (window.ENV.ENVIRONMENT.toUpperCase() === 'DEV'
            || window.ENV.ENVIRONMENT.toUpperCase() === 'DEVELOPMENT')) {
          console.log('(development environment). Log argument is HTTP Cancel. Will not report.');
        }
      }
    });
    if (isHttpCancel) {
      return;
    }

    //
    // Report
    //
    if (logLevel && ['fatal', 'error', 'warn', 'info'].includes(logLevel)) {
      let message = '';

      const authStore = useAuthStore();
      const globalStore = useGlobalStore();

      const { currentVersion } = globalStore;
      if (currentVersion) {
        message = `${message} v${currentVersion} |`;
      } else {
        message = `${message} v?.?.? |`;
      }

      const accountStore = useAccountStore();
      const userId = accountStore.userProfile && accountStore.userProfile.id;
      if (userId) {
        if (authStore.isImpersonating) {
          message = `${message} [IMPERSONATED] User ID ${userId} |`;
        } else {
          message = `${message} User ID ${userId} |`;
        }
      } else {
        message = `${message} No user ID |`;
      }

      if (clientIp) {
        message = `${message} ${clientIp} |`;
      }

      const { userRole } = accountStore;
      if (userRole) {
        message = `${message} Role ID ${userRole.id} (${userRole.code} / ${userRole.name}) |`;
      } else {
        message = `${message} No role |`;
      }

      let routeInfo = '';
      const router = app.config.globalProperties.$router;

      if (router) {
        const { currentRoute } = router;
        routeInfo = `route: ${currentRoute.value.name?.toString()} q: ${stringifySafe(currentRoute.value.query)}`;
      } else {
        routeInfo = `no router; window location href: ${window.location.href}`;
      }
      message = `${message} ${routeInfo} |`;

      message = `${message} ${logLevelPrefix} ${methodNamePrefix} ${formattedArguments
        .map((arg) => {
          if (typeof arg === 'string') {
            return arg;
          }
          // Use JSON.stringify to avoid converting objects to "[object Object]"
          return stringifySafe(arg); // use our safe stringify to avoid TypeError: Converting circular structure to JSON
        })
        .join(' ')}`;
      // Add stack trace if not info nor debug
      // if (['fatal', 'error', 'warn'].includes(logLevel)) {
      //   message = `${message} | Stack: ${new Error().stack}`;
      // }
      // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx');
      // console.log(message); // to test what will be sent

      if (authStore.isAuthenticated) {
        const postData = {
          id: APP_ID,
          level: logLevel === 'fatal' ? 'error' : logLevel, // possible: error, warn, info
          message,
        };

        if (process.env.NODE_ENV !== 'production') {
          // console.log('Development build. Not POSTing log. Would send:', postData);
          return;
        }

        // Send to Sentry
        if (['fatal', 'error', 'warn'].includes(logLevel)) {
          Sentry.captureMessage(message);
        }

        // Send to backend
        try {
          const http = app.config.globalProperties.$http;
          if (http) {
            await http.post('/session/log', postData, { doNotLogErrorAgainIfThisRequestFails: true, signal: undefined });
          }
        } catch {
          console.warn('Could not log the message. Is the server running?');
        } finally {
          //
        }
      }
    }
  }

  function initLoggerInstance(options: Options, logLevels: typeof logLevelsArray) {
    const logger: {
      [level: string]: (...data: any[]) => void;
    } = {};
    logLevels.forEach((logLevel) => {
      if (logLevels.indexOf(logLevel) >= logLevels.indexOf(options.logLevel) && options.isEnabled) {
        logger[logLevel] = (...args: unknown[]) => {
          const methodName = getMethodName();
          const methodNamePrefix = options.showMethodName ? `${methodName} ${options.separator}` : '';
          const logLevelPrefix = options.showLogLevel ? `${logLevel} ${options.separator}` : '';
          const formattedArguments = options.stringifyArguments ? args.map((a) => stringifySafe(a)) : args;
          printLogMessage(
            logLevel,
            logLevelPrefix,
            methodNamePrefix,
            formattedArguments,
            options.showConsoleColors,
          );
        };
      } else {
        logger[logLevel] = () => {
        };
      }
    });
    return logger;
  }

  function isValidOptions(options: unknown, logLevels: string[]) {
    if (!(typeof options === 'object')) {
      return false;
    }
    if (!options) {
      return false;
    }
    if (!('logLevel' in options
      && 'stringifyArguments' in options
      && 'showLogLevel' in options
      && 'showConsoleColors' in options
      && 'separator' in options
      && 'isEnabled' in options
      && 'showMethodName' in options
    )) {
      return false;
    }
    if (!(options.logLevel && typeof options.logLevel === 'string' && logLevels.includes(options.logLevel))) {
      return false;
    }
    if (options.stringifyArguments && typeof options.stringifyArguments !== 'boolean') {
      return false;
    }
    if (options.showLogLevel && typeof options.showLogLevel !== 'boolean') {
      return false;
    }
    if (options.showConsoleColors && typeof options.showConsoleColors !== 'boolean') {
      return false;
    }
    if (options.separator && (typeof options.separator !== 'string' || (typeof options.separator === 'string' && options.separator.length > 3))) {
      return false;
    }
    if (typeof options.isEnabled !== 'boolean') {
      return false;
    }
    return !(options.showMethodName && typeof options.showMethodName !== 'boolean');
  }

  async function getIP() {
    let json;

    try {
      const response = await fetch('https://api.ipify.org/?format=json');
      json = await response.json();
    } catch (error) {
      if (error instanceof SyntaxError) {
        // Unexpected token < in JSON
        console.log('getIP: there was a SyntaxError', error);
      } else {
        console.log('getIP: there was an error', error);
      }
    }

    if (json && 'ip' in json) {
      clientIp = json.ip;
      // console.log(`getIP: ${clientIp}`);
    }
  }

  function install(appParam: App, optionsArg: Options) {
    const options = { ...defaultOptions, ...optionsArg };
    // if ('store' in options) {
    //   ({ store } = options);
    // }
    // http = Vue.prototype.$http;
    // http = app.config.globalProperties.$http;
    // if (!http) {
    //   console.log('Warning: Vue did not have $http.');
    // }

    // router = app.config.globalProperties.router;
    // if (!router) {
    //   console.log('Warning: Vue did not have router.');
    // }
    app = appParam;

    if (isValidOptions(options, logLevelsArray)) {
      // inject a globally available $log
      appParam.config.globalProperties.$log = initLoggerInstance(options, logLevelsArray) as ComponentCustomProperties['$log'];
      app = appParam;

      getIP();
    } else {
      throw new Error('Provided options for vuejs-logger are not valid.');
    }
  }

  return {
    install,
    // isValidOptions,
    // printLogMessage,
    // initLoggerInstance,
    // logLevelsArray,
  };
})();
