(() => {
  console.log('xxxxx 3) INITIAL CONFIG');

  const INVALID_BROWSER_ELEM = document.getElementById('invalid-browser-custom-message');
  const NO_API_ELEM = document.getElementById('no-api-message');
  const LOGO_ELEMS = <HTMLCollectionOf<HTMLImageElement>>document.getElementsByClassName('logo-custom');
  const ERROR_BG_ELEMS = <HTMLCollectionOf<HTMLElement>>document.getElementsByClassName('background-image-error');
  const APP_PRELOAD_ELEM = document.getElementById('app-preload');

  const config = {
    title: '',
    favicon: '',
    invalidBrowser: '',
    noApi: '',
    logoSrc: '',
    errorBackgroundSrc: '',
    appClass: '',
  };

  function setupImp() {
    config.title = 'impulzity | better impulze. better outcome.';
    config.invalidBrowser = 'We’re sorry but impulzity doesn’t work on this browser version.';
    config.appClass = 'imp';
    config.favicon = '/favicon.ico';
    config.noApi = 'The system is being updated. Please come back in a while.';
    config.logoSrc = '/img/logo-v3.png';
  }

  function setupJgo() {
    config.title = 'Jaagou | Talent Manuals for humans.';
    config.invalidBrowser = 'We’re sorry but Jaagou doesn’t work on this browser version.';
    config.noApi = 'The system is being updated. Please come back in a while.';
    config.appClass = 'jgo';
    if (window.ENV && window.ENV.THEME === 'v2') {
      config.logoSrc = '/img/jgo/jgo-JGO_2019_CI_Logo_Original_Grey_SW_008.png';
      config.favicon = '/favicon.png'; // comes from /to_be_copied_by_webpack_to_dist/jgo/favicon.png
    } else {
      config.logoSrc = '/img/jgo/JGO_2022_Logo_Original_Turquoise-White_008.png';
      config.favicon = '/img/jgo/JGO_2022_Logo_Icon_Original_Turquoise-White_008.png'; // TODO: move to /to_be_copied_by_webpack_to_dist/jgo/favicon.png ?
    }
  }

  function setupTly() {
    config.title = 'treazrly | finding treasures. combining treasures.';
    config.favicon = '/favicon.png';
    config.invalidBrowser = 'We’re sorry but Treazrly doesn’t work on this browser version.';
    config.noApi = 'The system is being updated. Please come back in a while.';
    config.logoSrc = '/img/tly/TLY_2021_CI_Logo_original_008.svg';
    config.appClass = 'tly';
  }

  function setupZly() {
    config.title = 'zlightly | slightly brighter. slightly lighter.';
    config.favicon = '/favicon.png';
    config.invalidBrowser = 'We’re sorry but zlightly doesn’t work on this browser version.';
    config.noApi = 'The system is being updated. Please come back in a while.';
    config.logoSrc = '/img/zly/ZLY_2021_Logo_Original_Full_01_Purple-White_008.png';
    config.appClass = 'zly';
  }

  function setupZae() {
    config.title = 'zaemmy | THE first digital «Relationship Mentoring».';
    config.favicon = '/favicon.png';
    config.invalidBrowser = 'We’re sorry but zaemmy doesn’t work on this browser version.';
    config.noApi = 'The system is being updated. Please come back in a while.';
    config.logoSrc = '/img/zae/zae_logo.png';
    config.appClass = 'zae';
  }

  function setupFavicon() {
    /*!
     * Dynamically changing favicons with JavaScript
     * Works in all A-grade browsers except Safari and Internet Explorer
     * Demo: http://mathiasbynens.be/demo/dynamic-favicons
     */

    // HTML5™, baby! http://mathiasbynens.be/notes/document-head
    // document.head = document.head || document.getElementsByTagName('head')[0];
    if (!document.head) {
      console.log('setupFavicon(): no document.head');
      return;
    }

    function changeFavicon(src: string) {
      // <link rel="icon" href="<%= BASE_URL %>favicon.ico">
      const link = document.createElement('link');
      const oldLink = document.getElementById('dynamic-favicon');
      link.id = 'dynamic-favicon';
      link.rel = 'shortcut icon';
      link.href = `${src}?=${Math.random()}`; // avoid cache
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }

    changeFavicon(config.favicon);
  }

  function go() {
    document.title = config.title;

    if (INVALID_BROWSER_ELEM) {
      INVALID_BROWSER_ELEM.innerHTML = config.invalidBrowser;
    }

    if (NO_API_ELEM) {
      NO_API_ELEM.innerHTML = config.noApi;
    }

    const logosLen = LOGO_ELEMS.length;
    for (let i = 0; i < logosLen; i += 1) {
      LOGO_ELEMS[i].classList.add(config.appClass);
      LOGO_ELEMS[i].src = config.logoSrc;
    }

    const errorBgsLen = ERROR_BG_ELEMS.length;
    for (let i = 0; i < errorBgsLen; i += 1) {
      if (config.errorBackgroundSrc) {
        ERROR_BG_ELEMS[i].style.background = `url(${config.errorBackgroundSrc}) no-repeat center`;
        ERROR_BG_ELEMS[i].style.backgroundSize = 'cover';
      } else {
        ERROR_BG_ELEMS[i].style.background = '#272d33';
      }
    }

    if (APP_PRELOAD_ELEM) {
      APP_PRELOAD_ELEM.classList.add(config.appClass);
    }

    setupFavicon();
  }

  switch (import.meta.env.VITE_APP_NAME) {
    case 'imp':
      setupImp();
      break;
    case 'jgo':
      setupJgo();
      break;
    case 'tly':
      setupTly();
      break;
    case 'zly':
      setupZly();
      break;
    case 'zae':
      setupZae();
      break;
    default:
      throw new Error(`Invalid VITE_APP_NAME value ${import.meta.env.VITE_APP_NAME}`);
  }

  go();
})();
