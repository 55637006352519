<template>
  <!--<div-->
  <!--v-if="sosIsOn"-->
  <!--class="item-notification-indicator"-->
  <!--@click="sosIsOn = false"-->
  <!--/>-->
  <BBadge
    v-if="count"
    v-tooltip="tooltip ? tooltip : (notificationType === 'active-impulse' && count ? 'There is an open impulze' : '')"
    :class="{ 'nuggets': notificationType === 'nuggets', 'no-highlight': noHighlight }"
    pill
    variant="danger"
  >
    {{ count }}
  </BBadge>
</template>

<script
  setup
  lang="ts"
>
import { computed } from 'vue';
import { useGlobalStore } from '@/stores/global';

const props = defineProps({
  notificationType: {
    type: String,
    required: true,
  },
  counter: {
    type: Number,
    default: 0,
  },
  noHighlight: {
    type: Boolean,
    default: false,
  },
  tooltip: {
    type: String,
    default: '',
  },
});

const globalStore = useGlobalStore();

const count = computed(() => {
  switch (props.notificationType) {
    case 'help-requests':
      return globalStore.receivedHelpRequestsCount;
    case 'nuggets':
      return globalStore.receivedNuggetsCount;
    default:
      return props.counter;
  }
});
</script>

<style
  lang="scss"
  scoped
>
.rounded-pill {
  // used by <BBadge>
  --bs-border-radius-pill: 50%;
  font-size: $font-08px;
}

.badge.bg-danger {
  color: $light-primary;

  .theme-v3e & {
    --bs-danger-rgb: var(--accent-color_rgb);
  }

  &.no-highlight {
    --bs-danger-rgb: var(--text-color-1_rbg);
  }

  &.nuggets {
    --bs-danger-rgb: goldenrod;
  }
}
</style>
