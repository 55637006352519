// Firebase
// Import the functions you need from the SDKs you need
import type { App } from 'vue';
import { type FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { type MessagePayload, type Messaging, deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging';
import type { ToastObject } from 'node_modules/@hoppscotch/vue-toasted/dist';

const LOG_TAG = 'PushNotifications';

let app: App;
let token: null | string = null;
let firebaseApp: FirebaseApp;
let messaging: Messaging;
let globalStore: ReturnType<typeof import('./stores/global.ts')['useGlobalStore']>;

function injectApp(appParam: App) {
  app = appParam;
}

function setGlobalStore(storeParam: ReturnType<typeof import('./stores/global.ts')['useGlobalStore']>) {
  globalStore = storeParam;
}

async function sendTokenToServer() {
  if (token) {
    try {
      app.config.globalProperties.$http.post('/notifications/register', { token }, { signal: undefined });
      app.config.globalProperties.$log.debug(`${LOG_TAG}: sendTokenToServer: sent.`);
    } catch (error) {
      app.config.globalProperties.$log.error(`${LOG_TAG}: sendTokenToServer: error sending:`, error);
    } finally {
      //
    }
  }
}

async function subscribeToPushNotifications() {
  if (messaging === undefined) {
    app.config.globalProperties.$log.debug(`${LOG_TAG}: subscribeToPushNotifications(): messaging is not defined.`);
    return;
  }

  try {
    // const currentToken = await getToken(messaging, { vapidKey: '<YOUR_PUBLIC_VAPID_KEY_HERE>' })
    // https://firebase.google.com/docs/reference/js/messaging_
    // getToken(messaging, options):
    // Subscribes the Messaging instance to push notifications. Returns a Firebase Cloud Messaging registration token
    // that can be used to send push messages to that Messaging instance.
    // If a notification permission isn't already granted, this method asks the user for permission.
    // The returned promise rejects if the user does not allow the app to show notifications.
    const currentToken = await getToken(messaging, { vapidKey: 'BGxBibflOg56wKQxE9cqynfk44VkywuwSqeqdrbhlo9a969Z6oOs05iwv0AOn8NbIlFSM5Z4PDumcJ3M5il3CWM' });
    if (currentToken) {
      app.config.globalProperties.$log.debug(`${LOG_TAG}: getToken(): got Instance ID token:`, currentToken); // token to use in the notification
      token = currentToken;
      await sendTokenToServer();
      // updateUIForPushEnabled(currentToken);
    } else {
      // Show permission request.
      app.config.globalProperties.$log.debug(`${LOG_TAG}: getToken(): no Instance ID token available. Requesting permission to generate one.`);
      // Show permission UI.

      // updateUIForPushPermissionRequired();
      // setTokenSentToServer(false);
    }
  } catch (error) {
    app.config.globalProperties.$log.warn(`${LOG_TAG}: getToken(): an error occurred while retrieving token. `, error);
    // showToken('Error retrieving Instance ID token. ', error);
    // setTokenSentToServer(false);
  }
}

// async function updateUIForPushPermissionRequired() {
//   if (messaging === undefined) {
//     app.config.globalProperties.$log.debug(`${LOG_TAG}: updateUIForPushPermissionRequired(): messaging is not defined.`);
//     return;
//   }
//
//   try {
//     // Retrieve an Instance ID token for use with FCM.
//     await subscribeToPushNotifications();
//   } catch (error) {
//     app.config.globalProperties.$log.info(`${LOG_TAG}: requestPermission(): unable to get permission to notify.`, error);
//   } finally {
//     //
//   }
// }

function sendToast(message: MessagePayload) {
  if (!message.notification) {
    return;
  }
  const isHelpRequest = message.notification.body && message.notification.body.endsWith('needs your help!'); // workaround
  if (isHelpRequest && globalStore) {
    // globalStore.checkReceivedHelpRequestsCount();
    globalStore.checkAlarms();
  }

  let img = '';

  if (!isHelpRequest) {
    img = `
      <img
        src="/email/boarding-pass-logo-small.jpg"
        width="50"
        alt="impulzity logo"
        class="mr-gap-0_38x"
        style="margin-bottom: 2px;"
      >
    `;
    // height="61"
  }

  const toastHtml = `
    ${img}
    <div class="d-flex flex-column mt-gap-0_38x mb-gap-0_38x mr-gap-1x">
      <div class="custom-notification-title">
          ${message.notification.title}
      </div>
      <div>
          ${message.notification.body}
      </div>
    </div>
    `;

  app.config.globalProperties.$toasted.show(toastHtml, {
    className: isHelpRequest ? 'toast-urgent' : 'dark-toast',
    type: 'info',
    // theme: 'bubble', // ['toasted-primary' (default), 'outline', 'bubble']
    duration: 0,
    action: [
      {
        text: 'Open',
        onClick: (e: Event, toastObject: ToastObject) => {
          globalStore.performActionUponNotification(message);
          toastObject.goAway(0);
        },
      },
      {
        text: '×',
        class: 'close-toast',
        onClick: (e: Event, toastObject: ToastObject) => {
          toastObject.goAway(0);
        },
      },
    ],
  });

  // A new $bvToast injection (mixin) is created for each Vue virtual machine
  // (i.e. each instantiated component), and is not usable via direct access
  // to the Vue.prototype, as it needs access to the instance's this and $root contexts
  //
  // Vue.prototype.$bvToast.toast(message.notification.body, {
  //   title: message.notification.title,
  //   autoHideDelay: 5000,
  //   // variant: 'danger',
  //   solid: true,
  //   toaster: 'b-toaster-bottom-right',
  //   // appendToast: append,
  // });
}

function createFirebaseApp(config = {}) {
  try {
    return getApp();
  } catch {
    app.config.globalProperties.$log.debug(`${LOG_TAG}: could not get existing Firebase app, will initialize with this config:`, config);
    return initializeApp(config);
  }
}

async function initializeFirebase() {
  app.config.globalProperties.$log.debug(`${LOG_TAG}: initializeFirebase()`);
  // const DISABLED = true;
  // if (DISABLED) {
  //   return;
  // }
  //
  // Called on main.js to register the Service Worker
  //

  if (!('serviceWorker' in navigator)) {
    // Not supported!
    app.config.globalProperties.$log.debug(`${LOG_TAG}: initializeFirebase(): service worker not supported by this browser.`);
    return;
  }

  if (!window.ENV || !window.ENV.FIREBASE) {
    app.config.globalProperties.$log.warn(`${LOG_TAG}: ENV or ENV.FIREBASE not set on window. Skipping.`);
    return;
  }

  const firebaseConfig = JSON.parse(atob(window.ENV.FIREBASE));
  firebaseApp = createFirebaseApp(firebaseConfig);

  // Unregister old registrations (not needed)
  // try {
  //   const registrations = await navigator.serviceWorker.getRegistrations();
  //   registrations.forEach((registration) => {
  //     registration.unregister();
  //   });
  // } catch (error) {
  //   //
  // } finally {
  //   //
  // }

  // try {
  //   const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
  //   app.config.globalProperties.$log.debug(`${LOG_TAG}: service worker is registered.`);
  //   // Retrieve Firebase Messaging object.
  //   Vue.prototype.$messaging = firebase.messaging();
  //   Vue.prototype.$messaging.useServiceWorker(registration);
  //   // Is the user authenticated?
  //   if (store.getters['auth/isAuthenticated']) {
  //     app.config.globalProperties.$log.debug(`${LOG_TAG}: user is authenticated, will now setup notifications.`);
  //     signedIn();
  //   }
  // } catch (error) {
  //   app.config.globalProperties.$log.error(error);
  // } finally {
  //   //
  // }

  messaging = getMessaging(firebaseApp);
  app.config.globalProperties.$log.debug(`${LOG_TAG}: got messaging for the firebase app:`, messaging);
  await subscribeToPushNotifications();

  // const testMessage = {
  //   notification: {
  //     title: 'Title here',
  //     body: 'Body body body body here',
  //   },
  // };
  // setTimeout(() => {
  //   sendToast(testMessage);
  // }, 2000);

  app.config.globalProperties.$log.debug(`${LOG_TAG}: registering onMessage.`);
  onMessage(messaging, (payload) => {
    app.config.globalProperties.$log.debug(`${LOG_TAG}: message received.`, payload);
    // {
    //   collapse_key: "do_not_collapse"
    //   from: "1013948601127"
    //   notification: {
    //     body: "this is the body"
    //     title: "this is the title"
    //   }
    // }
    if ('notification' in payload) {
      sendToast(payload);
    }
  });

  // Callback fired if Instance ID token is updated.
  // app.config.globalProperties.$log.debug(`${LOG_TAG}: registering onTokenRefresh.`);
  // Vue.prototype.$messaging.onTokenRefresh(async () => {
  //   // https://github.com/firebase/firebase-js-sdk/issues/4132
  //   // "Token refresh was never actually implemented in the JS SDK. The API is a no-op, and we don't plan to implement it anytime soon, so we decided to deprecate and eventually remove it."
  //   try {
  //     const refreshedToken = await Vue.prototype.$messaging.getToken();
  //     app.config.globalProperties.$log.debug(`${LOG_TAG}: Token refreshed.`, refreshedToken);
  //     // Indicate that the new Instance ID token has not yet been sent to the
  //     // app server.
  //     // setTokenSentToServer(false);
  //     // Send Instance ID token to app server.
  //     token = refreshedToken;
  //     await sendTokenToServer();
  //     // ...
  //   } catch (error) {
  //     app.config.globalProperties.$log.error(`${LOG_TAG}: Unable to retrieve refreshed token `, error);
  //     // showToken('Unable to retrieve refreshed token ', error);
  //   } finally {
  //     //
  //   }
  // });
}

// const injectApp = (appParam: App) => {
//   app = appParam;
// };

// const setGlobalStore = (storeParam: ReturnType<typeof import('./stores/global.ts')['useGlobalStore']>) => {
//   globalStore = storeParam;
// };

async function signedIn() {
  //
  // Called by auth.ts after the user is authenticated
  //
  // if (messaging === undefined) {
  //   app.config.globalProperties.$log.debug(`${LOG_TAG}: signedIn(): messaging is not defined.`);
  //   return;
  // }
  //
  // // Get Instance ID token. Initially this makes a network call, once retrieved
  // // subsequent calls to getToken will return from cache.
  // subscribeToPushNotifications();
  app.config.globalProperties.$log.debug(`${LOG_TAG}: signedIn: will initialize Firebase.`);
  await initializeFirebase();
}

async function signedOut() {
  if (messaging === undefined) {
    app.config.globalProperties.$log.debug(`${LOG_TAG}: signedOut(): messaging is not defined.`);
    return;
  }

  if (!token) {
    app.config.globalProperties.$log.debug(`${LOG_TAG}: signed out but there is no token.`);
    return;
  }

  app.config.globalProperties.$log.debug(`${LOG_TAG}: signed out, will delete the token on Firebase.`);
  try {
    // $http.delete receives data inside config instead of 2nd arg. No need to wait for this request
    await app.config.globalProperties.$http.delete('/notifications/register', { data: { token }, signal: undefined });
    // Delete from Firebase
    await deleteToken(messaging);
    app.config.globalProperties.$log.debug(`${LOG_TAG}: token deleted (Firebase).`);
    token = null;
  } catch (error) {
    app.config.globalProperties.$log.error(`${LOG_TAG}: could not delete token.`, error);
  } finally {
    //
  }
}

export default {
  injectApp,
  setGlobalStore,
  initializeFirebase,
  signedIn,
  signedOut,
};
